<template>
  <div>
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="4" :fill="fill"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'WhereCircleIcon',
  props: {
    fill: {
      type: String,
      default: '#A0AEE0',
    },
  },
}
</script>