<template>
  <AdminCard title="Números gerais">
    <ul class="where-dash-list">
      <li class="where-dash-list-item">
        <div class="where-dash-list-item-icon">
          <img :src="require('../../../../images/admin/locations.svg')" />
        </div>
        <div class="where-dash-list-item-text">
          <strong>{{ units }}</strong>
          <p>Unidades</p>
        </div>
      </li>
      <li class="where-dash-list-item">
        <div class="where-dash-list-item-icon">
          <img :src="require('../../../../images/admin/floors.svg')" />
        </div>
        <div class="where-dash-list-item-text">
          <strong>{{ floors }}</strong>
          <p>Pisos</p>
        </div>
      </li>
      <li class="where-dash-list-item">
        <div class="where-dash-list-item-icon">
          <img :src="require('../../../../images/admin/qrcode.svg')" />
        </div>
        <div class="where-dash-list-item-text">
          <strong>{{ bookable }} </strong>
          <p>Reserváveis</p>
        </div>
      </li>
      <li class="where-dash-list-item">
        <div class="where-dash-list-item-icon">
          <img :src="require('../../../../images/admin/users.svg')" />
        </div>
        <div class="where-dash-list-item-text">
          <strong>{{ teams }}</strong>
          <p>Grupos</p>
        </div>
      </li>
    </ul>
  </AdminCard>
</template>

<script>
import AdminCard from "./card";
import DashboardService from "../../services/DashboardService";

export default {
  name: "AdminOverview",
  components: {
    AdminCard
  },
  data() {
    return {
      units: 0,
      floors: 0,
      bookable: 0,
      teams: 0,
    }
  },
  async mounted() {
    await this.getOverview();
  },
  methods: {
    async getOverview() {
      try {
        const { data } = await DashboardService.overview()
        this.units = data.units;
        this.floors = data.floors;
        this.bookable = data.bookable;
        this.teams = data.teams;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.where-dash-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 24px;
  margin-bottom: 0;
}

.where-dash-list-item {
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;


}
.where-dash-list-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #f7f7f7;
  border-radius: 100px;
  margin-right: 16px;

  img {
    width: 20px;
  }
}
.where-dash-list-item-text {
  font-family: "Montserrat", sans-serif;
  p {
    color: #82818c;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin: 0;
  }
  strong {
    color: #151223;
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
  }
}
</style>
