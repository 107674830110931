<template>
  <Dropdown placement="left">
    <button type="button" class="period-button">{{ periodLabel }}</button>
    <template #popper="{ hide }">
      <ul class="period-button-list">
        <li v-for="item in periods" :key="item.value" class="period-button-list-item">
          <button type="button" @click="handlePeriod(item.value, hide)">
            {{ item.label }}
          </button>
        </li>
      </ul>
      <div class="calendar" v-show="isCustom">
        <VDatePicker
          locale="pt-BR"
          v-model="range"
          @input="handleRange(hide)"
          is-range
        />
      </div>
    </template>
  </Dropdown>
</template>

<script>
import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import { Dropdown } from "floating-vue";
import QueryParamsMixin from "../../mixins/query-params";
import { format } from "date-fns";

export default {
  components: {
    Dropdown,
    VDatePicker,
  },
  props: {
    periods: {
      type: Array,
      default: () => [
        {
          label: "Hoje",
          value: "today",
        },
        {
          label: "Ontem",
          value: "yesterday",
        },
        {
          label: "Essa semana",
          value: "week",
        },
        {
          label: "Semana passada",
          value: "last_week",
        },
        {
          label: "Esse mês",
          value: "month",
        },
        {
          label: "Mês passado",
          value: "last_month",
        },
        {
          label: "Esse ano",
          value: "year",
        },
        {
          label: "Personalizar",
          value: "custom",
        },
      ]
    }
  },
  data: () => ({
    isCustom: false,
    range: {
      start: "",
      end: "",
    },
    currentPeriodLabel: "",
  }),
  mixins: [QueryParamsMixin],
  methods: {
    handlePeriod(value, hide) {
      if (value === "custom") {
        this.isCustom = true;
      } else {
        this.isCustom = false;
        this.$emit("change-period", value);
        this.currentPeriodLabel = value
        hide()
      }
    },
    handleRange(hide) {
      this.currentPeriodLabel = "custom"
      this.$emit("change-range", this.range);
      hide()
    },
  },
  computed: {
    periodLabel() {
      const period = this.currentPeriodLabel;

      if (period === "custom") {
        const formatedStart = format(this.range.start, "dd/MM/yyyy");
        const formatedEnd = format(this.range.end, "dd/MM/yyyy");

        return `Personalizado (${formatedStart} a ${formatedEnd})`;
      }

      if (period) {
        return this.periods.find(
          (item) => item.value === period
        ).label
      }

      return this.periods[0].label;
    },
  },
};
</script>

<style lang="scss" scoped>
.period-button {
  width: auto;
  background: transparent;
  color: #82818c;
  font-size: 14px;
  line-height: 16px;
  box-shadow: none;
  border: none;
  cursor: pointer;
  padding: 15px;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.period-button-list {
  margin: 0;
  padding: 0;
}

.period-button-list-item {
  list-style-type: none;

  button {
    width: 100%;
    background: transparent;
    border: none;
    box-shadow: none;
    color: #6e6b7b;
    text-align: left;
    padding: 15px;
    border-radius: 0;
    font-size: 14px;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.calendar {
  padding: 10px;
}
</style>

<style>
.v-popper__inner {
  border: none !important;
  box-shadow: 2px 2px 24px 8px rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px !important;
  padding: 0px !important;
  min-width: 150px;
}

.v-popper__arrow-outer {
  border-color: transparent !important;
}

.vc-title {
  text-transform: capitalize;
}
</style>
