var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminCard',{attrs:{"title":"Uso da plataforma"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('AdminPeriodButton',{on:{"change-period":_vm.changePeriod,"change-range":_vm.changeRange}})]},proxy:true}])},[_vm._v(" "),_c('ul',{staticClass:"list"},[_c('li',{staticClass:"list-item"},[_c('p',{staticClass:"list-item-text"},[_c('strong',[_vm._v(_vm._s(_vm.registeredUsers.total))]),_vm._v(" usuários cadastrados\n      ")]),_vm._v(" "),_c('ProgressBar',{attrs:{"total":_vm.registeredUsers.total,"blue":_vm.registeredUsers.active,"yellow":_vm.registeredUsers.inactive,"salmon":_vm.registeredUsers.blocked,"captions":{
          blue: 'ativos',
          yellow: 'inativos',
          salmon: 'bloqueados',
        }}})],1),_vm._v(" "),_c('li',{staticClass:"list-item"},[_c('p',{staticClass:"list-item-text"},[_c('strong',[_vm._v(_vm._s(_vm.scheduledReservations.total))]),_vm._v(" reservas agendadas\n      ")]),_vm._v(" "),_c('ProgressBar',{attrs:{"total":_vm.scheduledReservations.total,"blue":_vm.scheduledReservations.withCheckin,"salmon":_vm.scheduledReservations.withoutCheckin,"captions":{
          blue: 'com check-in',
          salmon: 'sem check-in',
        }}})],1),_vm._v(" "),_c('li',{staticClass:"list-item"},[_c('p',{staticClass:"list-item-text"},[_c('strong',[_vm._v(_vm._s(_vm.usersHomeOffice.active))]),_vm._v(" usuários ativaram home\n        office\n      ")]),_vm._v(" "),_c('ProgressBar',{attrs:{"blue":_vm.usersHomeOffice.active}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }