<template>
  <div class="container">
    <header class="where-header d-flex justify-content-between pt-3">
      <a
        v-if="kind === 'back'"
        :href="handleBack ? 'javascript:;' : '/users/home'"
        class="menu-icon"
        id="user-menu-action"
        @click="handleBack ? handleBack() : undefined"
      >
        <img :src="require('../../../images/arrow_left.svg')" height="14px" />
      </a>
      <a
        v-if="kind === 'home'"
        href="/users/menu"
        class="menu-icon"
        id="user-menu-action"
      >
        <img :src="require('../../../images/menu.svg')" height="12px" />
      </a>

      <a
        v-if="kind === 'close'"
        href="/users/home"
        class="menu-icon"
        id="user-menu-action"
      >
        <img :src="require('../../../images/close.svg')" height="12px" />
      </a>

      <img
        v-if="kind !== 'back'"
        :src="require('../../../images/logo-where-blue.svg')"
        height="18px"
        id="where-logo"
        class="center-align"
      />

      <slot></slot>

      <a
        v-if="kind !== 'back' || showSearch"
        href="/users/search"
        class="menu-icon"
      >
        <img :src="require('../../../images/icone_lupa.svg')" height="16px"
      /></a>
    </header>
  </div>
</template>

<script>
export default {
  props: {
    kind: {
      type: String,
      default: "home",
    },
    showSearch: Boolean,
    handleBack: Function,
  },
};
</script>

<style scoped>
.where-header {
  align-items: center;
}
</style>
