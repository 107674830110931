<template>
  <AdminCard ref="card" title="Histórico de Reservas" :no-body="true">
    <template v-slot:subtitle>
      <AdminPeriodButton @change-period="changePeriod" @change-range="changeRange" />
    </template>
    <table class="table responsive booking-table">
      <thead>
        <tr>
          <th>Usuário</th>
          <th>Atividade</th>
          <th>Espaço</th>
          <th>Duração</th>
          <th>Data</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(row, index) in rows" :key="index">
          <td>
            <div class="user-details">
              <div class="user-avatar">
                <img
                  style="max-height: 40px"
                  v-if="row.has_avatar"
                  :src="row.avatar_url"
                  :alt="row.name"
                />
                <div v-else class="avatar-text">
                  {{ getNickname(row.name) }}
                </div>
                <div
                  class="avatar-icon"
                  v-if="row.is_birthday || row.is_firefighter"
                >
                  <img
                    v-if="row.is_birthday"
                    :src="require('../../../../images/cake.svg')"
                    alt="Aniversário"
                  />
                  <img
                    v-if="row.is_firefighter"
                    :src="require('../../../../images/fire.svg')"
                    alt="Bombeiro"
                  />
                </div>
              </div>
              <div class="text">
                <strong>{{ row.name }}</strong>
                <p>{{ row.email }}</p>
              </div>
            </div>
          </td>
          <td>
            <p class="activity">{{ row.activity }}</p>
          </td>
          <td>
            <p class="space">{{ row.space }}</p>
          </td>
          <td>
            <p class="duration">{{ row.duration }}</p>
          </td>
          <td>
            <div class="date">
              <p>{{ row.date }}</p>
              <p>{{ row.starts_at }} - {{ row.finishes_at }}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end pr-2">
      <b-pagination
        v-if="totalRows > 10"
        hide-goto-end-buttons
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="10"
        prev-class="prev"
        next-class="next"
        @change="changePage" />
    </div>
  </AdminCard>
</template>

<script>
import AdminCard from "./card";
import AdminPeriodButton from "./period-button";
import DashboardService from "../../services/DashboardService";

export default {
  name: "AdminBookingHistory",
  components: {
    AdminCard,
    AdminPeriodButton,
  },
  data() {
    return {
      rows: [],
      currentPage: 1,
      totalRows: 0,
      period: 'today',
      range: null
    }
  },
  async mounted() {
    await this.getBookingHistory();
  },
  methods: {
    async getBookingHistory() {
      try {
        const { data } = await DashboardService.bookingHistory({ period: this.period, ...this.range, page: this.currentPage })
        this.currentPage = data.currentPage;
        this.rows = data.rows;
        this.totalRows = data.total;
      } catch (error) {
        console.log(error);
      }
    },
    getNickname(value) {
      const response = `${value.charAt(0)}${value.charAt(1)}`;

      return String(response).toUpperCase();
    },
    changePeriod(period) {
      this.period = period
      this.getBookingHistory();
    },
    changeRange(range) {
      this.period = "custom"
      this.range = range
      this.getBookingHistory();
    },
    changePage(value) {
      this.currentPage = value
      this.getBookingHistory();
    }
  },
};
</script>

<style lang="scss" scoped>
.booking-table {
  th {
    text-align: center;

    &:first-child {
      text-align: left;
      padding-left: 40px;
    }
  }
  td {
    vertical-align: middle;
    text-align: center;

    &:first-child {
      text-align: left;
      padding-left: 40px;
    }
  }

  p {
    margin: 0;
  }

  .user-details {
    display: flex;
    align-items: center;

    .user-avatar {
      width: 40px;
      height: 40px;
      color: #fff;
      margin-right: 18px;
      position: relative;

      img:first-child {
        width: 100%;
        border-radius: 50px;
      }

      .avatar-text {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #c4c4c4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
      }

      .avatar-icon {
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        position: absolute;
        right: -4px;
        bottom: -4px;
        z-index: 2;
        align-items: center;
        justify-content: center;
        background: #f7f7f4;

        img {
          width: 12px;
        }
      }
    }

    .text {
      strong {
        color: #404b5a;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
      }

      p {
        color: #82818c;
        opacity: 0.75;
        font-size: 11px;
        line-height: 16px;
      }
    }
  }

  .activity {
    color: #6e6b7b;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }

  .space {
    display: inline-block;
    background: #f7f7f7;
    padding: 3px;
    border-radius: 6px;
    color: #82818c;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
  }

  .duration {
    color: #6e6b7b;
    font-size: 13px;
    line-height: 16px;
  }

  .date p {
    font-size: 13px;
    line-height: 20px;
    color: #6e6b7b;
  }
}

$blue: #0a0a8c;

.page-item .page-link {
  background-color: #f3f2f7!important;
  border-radius: 0!important;
  margin: 0!important;
  box-shadow: none!important;
  color: $blue;
  border: none!important;
}

.page-item.active {
  background-color: #f3f2f7!important;
  border-radius: 0!important;
  border: none!important;
  .page-link {
    border-radius: 5rem!important;
    background-color: $blue!important;
  }
}

.page-item.disabled {
  border-color: #f3f2f7!important;
  span {
    max-height: 32px!important;
    padding-top: 5px!important;
  }
}

.page-item {
  border-radius: 5rem!important;
  &.prev {
    margin-right: 0.5rem!important;
    .page-link {
      border-radius: 50%!important;
    }
  }

  &.next {
    margin-left: 0.5rem!important;
    .page-link {
      border-radius: 50%!important;
    }
  }
}

.page-item:nth-child(2) {
  border-radius: 0!important;
  .page-link {
    border-top-left-radius: 5rem!important;
    border-bottom-left-radius: 5rem!important;
  }
}

.page-item:nth-last-child(2) {
  border-radius: 0!important;
  .page-link {
    border-top-right-radius: 5rem!important;
    border-bottom-right-radius: 5rem!important;
  }
}

.page-item.prev~.page-item:nth-last-child(2) {
  border-top-right-radius: 5rem!important;
  border-bottom-right-radius: 5rem!important;
}

.page-item.prev~.page-item:nth-child(2) {
  border-top-left-radius: 5rem!important;
  border-bottom-left-radius: 5rem!important;
}
</style>
