import BaseService from "./BaseService";

class HomeService extends BaseService {
  static async delete(id) {
    const axios = this.initialize();
    const request = await axios.delete(`/api/reservations/${id}/cancel`);
    return request.data;
  }
  static async deleteHomeOffice() {
    const axios = this.initialize();
    const request = await axios.delete(`/users/reservations/home_office`);
    return request.data;
  }
  static async backToOffice() {
    const axios = this.initialize();
    const request = await axios.get(`/users/reservations/back_to_office`);
    return request.data;
  }
}

export default HomeService;
