<template>
  <div class="where-reservations-step-4">
    <h2>Confirme a sua reserva</h2>
    <reservations-list :items="formattedItems" />
    <h6>RESERVE POR MAIS DIAS</h6>
    <div id="more_days_reservation" v-if="days.length">
      <table>
        <thead>
          <tr>
            <th v-for="(day, index) in days" :key="index">
              {{ showMonth(day, index) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th
              v-for="(day, index) in days"
              :key="index"
              :class="{ disabled: day.disabled }"
            >
              {{ day.day }}
            </th>
          </tr>
          <tr>
            <td
              v-for="(day, index) in days"
              :key="index"
              :class="{ disabled: day.disabled, selected: day.selected }"
              @click.prevent="selectDay(index)"
            >
              {{ day.date }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ReservationsList from "../../components/reservations-list.vue";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import ReservationService from "../../services/ReservationService";
import _ from "lodash";

export default {
  components: { ReservationsList },
  props: [
    "code",
    "location",
    "currentDate",
    "floor",
    "startAt",
    "finishesAt",
    "slots",
  ],
  async mounted() {
    const payload = {
      starts_at: this.$parent.startAt,
      finishes_at: this.$parent.finishesAt,
      code: this.$parent.code,
      floor_id: this.$parent.floor.id,
      date: this.$parent.currentDate,
    };
    const { data } = await ReservationService.getAvailabilityForMoreDays(
      payload
    );
    this.days = data;
  },
  data() {
    return {
      days: [],
    };
  },
  methods: {
    showMonth(day, index) {
      if (index === 0) {
        return day.month;
      }
      if (day.month !== this.days[index - 1].month) {
        return day.month;
      }
      return "";
    },
    getSplaceSlot(code, location, floor) {
      let response = `<span class='pt-3 pl-3 span-01 confirm-location-label'>${location}</span><span class='pt-1 pl-3 span-02 confirm-code-label'>${code}</span>`;

      if (this.slots.length > 1) {
        response = `${response}<span class='pt-1 pl-3 span-03 confirm-floor-label'>${this.startAt} - ${this.finishesAt} ${floor}</span>`;
      } else {
        response = `${response}<span class='pt-1 pl-3 span-03 confirm-floor-label'>${floor}</span>`;
      }

      return response;
    },
    selectDay(index) {
      if (this.days[index].disabled) return;

      this.days[index].selected = !this.days[index].selected;
      this.$emit("moreDaysReservation", this.reservationDays);
    },
  },
  computed: {
    formattedItems() {
      return [
        {
          id: 1,
          isToday: false,
          day: format(this.currentDate, "EEEE", { locale: ptBR }).substring(
            0,
            3
          ),
          shouldDisplayDay: true,
          shouldDisplayNumber: true,
          dayNumber: format(this.currentDate, "dd"),
          spaceSlot: this.getSplaceSlot(
            this.code,
            this.$parent.location.name,
            this.$parent.floor.name
          ),
        },
      ];
    },
    reservationDays() {
      let days = [];

      this.days.filter((day) => {
        if (day.selected) days.push(day.complete_date);
      });

      return days;
    },
  },
};
</script>

<style lang="scss" scoped>
.where-reservations-step-4 {
  padding: 0px 10px;
  width: -webkit-fill-available;

  height: 100%;
  text-align: left;

  h2 {
    padding-top: 40px;
  }

  h6 {
    margin-left: 5px !important;
    font-weight: 400 !important;
  }
}
</style>
