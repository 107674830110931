import BaseService from "./BaseService";

class FormService extends BaseService {
  static async submit({ form, choices, isValid }) {
    const axios = this.initialize();

    const params = {
      id: form.id,
      result: isValid ? 1 : 0,
    };

    form.questions.forEach((question, index) => {
      params[`question-form-answer-${question.id}`] = choices[index];
    });

    const request = await axios.post(`/users/form`, params);
    return request.data;
  }
}

export default FormService;
