<template>
  <AdminCard title="Uso da plataforma">
    <template v-slot:subtitle>
      <AdminPeriodButton @change-period="changePeriod" @change-range="changeRange" />
    </template>
    <ul class="list">
      <li class="list-item">
        <p class="list-item-text">
          <strong>{{ registeredUsers.total }}</strong> usuários cadastrados
        </p>
        <ProgressBar
          :total="registeredUsers.total"
          :blue="registeredUsers.active"
          :yellow="registeredUsers.inactive"
          :salmon="registeredUsers.blocked"
          :captions="{
            blue: 'ativos',
            yellow: 'inativos',
            salmon: 'bloqueados',
          }"
        />
      </li>
      <li class="list-item">
        <p class="list-item-text">
          <strong>{{ scheduledReservations.total }}</strong> reservas agendadas
        </p>

        <ProgressBar
          :total="scheduledReservations.total"
          :blue="scheduledReservations.withCheckin"
          :salmon="scheduledReservations.withoutCheckin"
          :captions="{
            blue: 'com check-in',
            salmon: 'sem check-in',
          }"
        />
      </li>
      <li class="list-item">
        <p class="list-item-text">
          <strong>{{ usersHomeOffice.active }}</strong> usuários ativaram home
          office
        </p>

        <ProgressBar :blue="usersHomeOffice.active" />
      </li>
    </ul>
  </AdminCard>
</template>

<script>
import AdminCard from "./card";
import ProgressBar from "../progress-bar";
import AdminPeriodButton from "./period-button";
import DashboardService from "../../services/DashboardService";

export default {
  name: "AdminUsage",
  components: {
    ProgressBar,
    AdminCard,
    AdminPeriodButton,
  },
  data() {
    return {
      registeredUsers: 0,
      scheduledReservations: 0,
      usersHomeOffice: 0,
      period: 'today',
      range: null
    }
  },
  async mounted() {
    await this.getUsage();
  },
  methods: {
    async getUsage() {
      try {
        const { data } = await DashboardService.usage({ period: this.period, ...this.range });
        this.registeredUsers = data.registeredUsers;
        this.scheduledReservations = data.scheduledReservations;
        this.usersHomeOffice = data.usersHomeOffice;
      } catch (error) {
        console.log(error);
      }
    },
    changePeriod(period) {
      this.period = period
      this.getUsage();
    },
    changeRange(range) {
      this.period = "custom"
      this.range = range
      this.getUsage();
    }
  }
};
</script>

<style lang="scss" scoped>
.hgroup {
  display: flex;
  justify-content: space-between;

  h4 {
    color: #82818c;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    text-transform: none;
  }
}

.list {
  margin: 0;
  padding: 0;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
}

.list-item {
  width: 100%;
  list-style-type: none;
}

.list-item-text {
  color: #82818c;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px !important;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;

  strong {
    font-size: 24px;
    line-height: 28px;
    color: #404b5a;
  }
}
</style>
