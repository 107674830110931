<template>
  <div class="qrcode-page">
    <div class="container">
      <div class="my-3">
        <a href="/users/home" class="menu-icon">
          <img
            :src="require('../../../../images/arrow_left.svg')"
            height="12px"
            class="mr-3"
          />
          Escaneie com QR-code
        </a>
      </div>
      <p class="where-qrcode-status" v-if="loading || error">
        <template v-if="loading">Carregando...</template
        ><template v-if="error">Não foi possível carregar uma câmera.</template>
      </p>

      <canvas id="canvas" style="width: 100%; height: 100%" hidden> </canvas>
    </div>

    <b-modal
      id="reservation-status-to-early"
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      hide-header
    >
      <template #default>
        <h2>
          <strong id="reservation-code">{{ space.code }}</strong
          ><br />
          confirmação de reserva
        </h2>
        <p class="mt-3 mb-5">
          Ainda é cedo, volte a partir de
          <u id="reservation-limit">{{ reservation.formated_checkable_at }}</u>
          para fazer o check-in e confirmar sua reserva.
        </p>
      </template>
      <template #modal-footer="{ ok }">
        <button type="button" class="btn btn-outlined" @click="ok()">
          ENTENDI
        </button>
      </template>
    </b-modal>

    <b-modal
      id="reservation-status-checkin"
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      hide-header
    >
      <template #default>
        <h2>
          <strong id="reservation-code">{{ space.code }}</strong
          ><br />
          confirmação de check-in
        </h2>
        <p class="mt-3 mb-5">
          Você tem uma reserva nesse espaço, deseja fazer o check-in?
        </p>
      </template>
      <template #modal-footer="{ cancel }">
        <button type="button" class="" @click="cancel()">VOLTAR</button>
        <button
          type="button"
          class="btn btn-outlined"
          @click="checkinOrCheckout()"
        >
          FAZER CHECK-IN
        </button>
      </template>
    </b-modal>

    <b-modal
      id="reservation-status-checkout"
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      hide-header
    >
      <template #default>
        <h2>
          <strong id="reservation-code">{{ space.code }}</strong
          ><br />
          reserva já confirmada
        </h2>
        <p class="mt-3 mb-5">
          Tudo certo, você já fez o check-in e sua reserva já está liberada!
        </p>
      </template>
      <template #modal-footer="{ cancel }">
        <button type="button" class="" @click="cancel()">VOLTAR</button>
        <button
          type="button"
          class="btn btn-outlined"
          @click="checkinOrCheckout()"
        >
          FAZER CHECK-OUT
        </button>
      </template>
    </b-modal>

    <b-modal id="modal-error" aria-labelledby="ModalLabel" aria-hidden="true" hide-header>
      <template #default>
        <h2>
          <strong id="reservation-code">{{ space.code }}</strong>
          <br />
          Indisponível
        </h2>
        <p class="mt-3 mb-5">
          {{ reservationError }}
        </p>
      </template>
      <template #modal-footer="{ ok }">
        <button type="button" class="btn btn-outlined" @click="ok()">
          FECHAR
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AppHeader from "../../components/app-header";
import QrcodeService from "../../services/QrcodeService";
import { format } from "date-fns";

export default {
  components: {
    AppHeader,
  },
  props: {
    currentUser: Object,
  },
  data() {
    return {
      requestStatus: '',
      loading: true,
      error: false,
      reservation: {},
      space: {},
      reservationError: null,
    };
  },
  async mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.externalSpaceId) {
      await this.checkReservationStatus(params.externalSpaceId);
      return;
    }
    let video = document.createElement("video");
    let canvasElement = document.getElementById("canvas");
    let canvas = canvasElement.getContext("2d");
    let videoStream = null;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });

      videoStream = stream;
      video.srcObject = stream;
      video.setAttribute("playsinline", true);
      video.play();

      requestAnimationFrame(tick);

      this.loading = false;
      this.error = false;
    } catch (e) {
      this.loading = false;
      this.error = true;
    }

    let that = this;

    async function tick() {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.hidden = false;

        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(
          video,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );

        var imageData = canvas.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "attemptBoth",
        });

        if (code && code.data) {
          videoStream.getTracks().forEach(function (track) {
            track.stop();
          });

          let url = code.data;
          let tokens = url.split("/");
          let id = parseInt(tokens[tokens.length - 1]);

          that.checkReservationStatus(id);

          return;
        }
      }

      requestAnimationFrame(tick);
    }
  },
  methods: {
    async checkReservationStatus(spaceId = null) {
      try {
        const request = await QrcodeService.reservationStatus(spaceId);
        this.requestStatus = request.status

        if (this.requestStatus == "no-reservation") {
          window.location.href = request.redirect_to;
        }

        request.reservation.formated_checkable_at = format(
          new Date(request.reservation.checkable_at),
          "HH:mm"
        );
        this.$set(this, "reservation", request.reservation);
        this.$set(this, "space", request.space);

        return this.$bvModal.show(`reservation-status-${this.requestStatus}`);
      } catch (e) {
        console.log(e);
        this.error = true;
      }
    },
    async checkinOrCheckout() {
      try {
        const request = await QrcodeService.send(this.reservation.id);
        window.location.href = request.redirect_url;
      } catch (error) {
        this.reservationError = error.response.data.message
        this.$bvModal.hide(`reservation-status-${this.requestStatus}`);
        this.$bvModal.show("modal-error");
      }
    },
  },
  watch: {},
};
</script>
