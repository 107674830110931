<template>
  <swipe-list :items="items" item-key="id" class="where-reservations">
    <template v-slot="{ item }">
      <div class="where-reservations__ticket">
        <div class="where-reservations__ticket__left">
          <p class="where-reservations__ticket__day">
            {{
              item.shouldDisplayDay ? (item.isToday ? "Hoje" : item.day) : ""
            }}
          </p>
          <p class="where-reservations__ticket__number">
            {{ item.shouldDisplayNumber ? item.dayNumber : "" }}
          </p>
        </div>
        <div class="where-reservations__ticket__right">
          <div
            v-html="item.spaceSlot"
            class="where-reservations__ticket__slot"
          />
          <div
            v-if="item.reservation_delete"
            class="where-reservations__ticket__clock"
          >
            <button
              v-if="item.hasCheckinUntil"
              type="button"
              class="where-reservations__ticket__clock-button"
              v-b-modal.modal-clock
              @click="handleSelectedReservation(item)"
            >
              <img height="20px" :src="require('../../../images/clock.svg')" />
              <small> {{ item.checkinUntil }}</small>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right="{ item }">
      <div class="swipeout-action">
        <div
          v-if="item.hasCancelable"
          class="where-reservations__ticket__delete"
        >
          <a
            v-b-modal.modal-delete
            @click.stop="handleCancelReservation($event, item)"
            ><img
              height="20px"
              class="icons"
              alt="Cancelar reserva"
              title="Cancelar reserva"
              :src="require('../../../images/trash_white.svg')"
          /></a>
        </div>
      </div>
    </template>
  </swipe-list>
</template>

<script>
import { SwipeList, SwipeOut } from "vue-swipe-actions";
import "vue-swipe-actions/dist/vue-swipe-actions.css";

export default {
  components: {
    SwipeOut,
    SwipeList,
  },
  props: ["items", "handleCancelReservation", "handleSelectedReservation"],
};
</script>

<style lang="scss">
.where-reservations {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 80px;
}

.where-reservations__ticket {
  display: flex;
  padding-bottom: 10px;
  width: 98%;
}

.where-reservations__ticket__left {
  width: 100px;
  text-align: center;
  flex-shrink: 0;
}

.where-reservations__ticket__day {
  margin: 0;
  font-weight: 400;
  font-size: 0.7rem !important;
  text-transform: capitalize;
}

.where-reservations__ticket__number {
  margin: 0;
}

.where-reservations__ticket__right {
  background: #fff;
  width: 100%;
  height: 100px;
  border-radius: 6px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  text-align: left;
}

.swipeout .swipeout-content {
  position: relative;
  z-index: 2;
}

.where-reservations__ticket__slot {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 11px;
  justify-content: center;
}

.where-reservations__ticket__slot span {
  padding: 0 !important;
}

.where-reservations__ticket__slot .confirm-location-label {
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 1px;
}

.where-reservations__ticket__slot .confirm-code-label {
  font-weight: 500;
}

.where-reservations__ticket__slot .confirm-floor-label {
  font-size: 0.7rem;
}

.where-reservations__ticket__clock {
  width: 100px;
  flex-shrink: 0;
  display: flex;
}

.where-reservations__ticket__clock-button {
  background: transparent;
  color: #222;
  font-size: 16px;
  border: none;
  box-shadow: none;
  border-left: 2px dotted rgba(0, 0, 0, 0.1);
  border-radius: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.where-reservations__ticket__delete a {
  background-color: #151223;
  width: 100%;
  min-width: 120px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  left: 0;
}
</style>
