import BaseService from "./BaseService";

class RecoverService extends BaseService {
  static async send(params) {
    const axios = this.initialize();

    const request = await axios.post(`/users/recover`, params);
    return request.data;
  }
}

export default RecoverService;
