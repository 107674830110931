<template>
  <div>
    <div class="mt-3">
      <a href="/users/menu" data-turbolinks="false" class="menu-icon">
        <img
          height="12px"
          :src="require('../../../../images/arrow_left.svg')"
        />
        <small class="ml-3">Preferências</small>
      </a>
    </div>
    <form @submit.prevent="handleSubmit">
      <div id="user-preferences">
        <div class="row px-4 mt-4">
          <div class="col-md-12">
            <h2>Em qual unidade você trabalha?</h2>
            <ul class="user-preferences-options mt-4 pl-0">
              <li
                v-for="(item, index) in locations"
                :key="index"
                class="user-preference-option"
                :class="{
                  'user-preference-option-enabled': form.location === item.id,
                }"
                @click="handleLocation(item)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row px-4 mt-4" v-if="currentCompany.id != 17">
          <div class="col-md-12">
            <h2>Qual seu número de celular?</h2>
            <input
              type="text"
              name="phone"
              id="phone"
              v-model="form.phone"
              class="user-preference-text form-control mt-4 br-flag"
              required="required"
              inputmode="text"
            />
          </div>
        </div>

        <div class="row px-4 mt-4" v-if="currentCompany.id != 17">
          <div class="col-md-12">
            <h2>Dia e mês de aniversário?</h2>
            <div class="d-flex justify-content-start mt-4">
              <select
                id="day"
                name="day"
                class="user-preference-birthday form-control"
                v-model="day"
              >
                <option>--</option>
                <option v-for="(day, index) in days" :key="index" :value="day">
                  {{ day }}
                </option>
              </select>

              <select
                id="month"
                name="month"
                class="user-preference-birthday form-control"
                v-model="month"
              >
                <option>--</option>
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="month.value"
                >
                  {{ month.label }}
                </option>
              </select>
            </div>

            <span
              id="user-preference-birthday-desc"
              class="user-preference-birthday-desc"
              style=""
              >{{ this.day }} de {{ getMonth(this.month) }}</span
            >
          </div>
        </div>

        <div class="row px-4 my-5">
          <div class="col-md-12">
            <h2>Você é brigadista?</h2>
            <ul class="user-preferences-options mt-4">
              <li
                class="float-left mr-4 user-preference-option"
                :class="{ 'user-preference-option-enabled': form.firefighter }"
                @click="() => (form.firefighter = true)"
              >
                Sim
              </li>
              <li
                class="float-left user-preference-option"
                :class="{ 'user-preference-option-enabled': !form.firefighter }"
                @click="() => (form.firefighter = false)"
              >
                Não
              </li>
            </ul>
          </div>
        </div>

        <div
          v-for="group in form.preferences"
          :key="group.id"
          class="row px-4 mb-4"
        >
          <div class="col-md-12 mb-4">
            <h2>
              {{ group.description }}
            </h2>
            <ul class="user-preferences-options mt-4">
              <li
                class="user-preference-option"
                v-for="(option, subindex) in group.options"
                :key="subindex"
                :class="{
                  'user-preference-option-enabled': group.values.includes(
                    option.id
                  ),
                }"
                @click="() => handleOption(group, option)"
              >
                {{ option.name }}
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-scroll">
          <div class="container">
            <button
              name="button"
              type="submit"
              class="user-preference-action-button btn-default"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AppHeader from "../../components/app-header";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import ProfileService from "../../services/ProfileService";

export default {
  components: {
    AppHeader,
  },
  props: {
    currentUser: Object,
    currentCompany: Object,
    preferenceGroups: Array,
    locations: Array,
  },
  data() {
    return {
      day: this.currentUser.birthday
        ? Number(this.currentUser.birthday.split("-")[2])
        : "",
      month: this.currentUser.birthday
        ? Number(this.currentUser.birthday.split("-")[1])
        : "",
      form: {
        location: this.currentUser.location,
        phone: this.currentUser.phone,
        birthday: this.currentUser.birthday,
        firefighter: this.currentUser.isFirefighter,
        preferences: this.preferenceGroups.map((item) => ({
          id: item.id,
          description: item.description,
          choices: item.options_qty,
          maxOptions: item.max_options_qty,
          required: item.required,
          options: item.preferences,
          values: this.currentUser.preferences
            .filter((el) => el.preference_group_id === item.id)
            .map((el) => el.id),
        })),
      },
    };
  },
  computed: {
    days() {
      return Array.from(Array(31).keys(), (value) => value + 1);
    },
    months() {
      const response = Array.from(
        Array(12).keys(),
        (value) => new Date(2021, value, 1)
      );

      return response
        .map((date) => ({
          label: format(date, "MMMM", { locale: ptBR }),
          value: format(date, "MM"),
        }))
        .map((month) => ({
          label: `${month.label.charAt(0).toUpperCase()}${month.label.slice(
            1
          )}`,
          value: Number(month.value),
        }));
    },
  },
  methods: {
    handleLocation(location) {
      this.form.location = location.id;
    },
    handleOption(group, option) {
      const maxChoices = group.choices;
      const groupIndex = this.form.preferences.findIndex(
        (el) => el.id === group.id
      );

      let shouldUpdate =
        this.form.preferences[groupIndex].values.length < maxChoices;
      const isIncluded = this.form.preferences[groupIndex].values.includes(
        option.id
      );

      let values = isIncluded
        ? this.form.preferences[groupIndex].values.filter(
            (el) => el !== option.id
          )
        : [...this.form.preferences[groupIndex].values, option.id];

      if (maxChoices === 1) {
        shouldUpdate = true;
        values = [option.id];
      }

      this.$set(this.form.preferences, groupIndex, {
        ...this.form.preferences[groupIndex],
        values:
          shouldUpdate || isIncluded
            ? values
            : this.form.preferences[groupIndex].values,
      });
    },
    async handleSubmit() {
      const { preferences, ...rest } = this.form;

      const params = {
        ...rest,
        locations: rest.location,
        day: this.day,
        month: this.month,
        birthday: rest.birthday.replace("1970-", ""),
      };

      preferences.forEach((preference) => {
        params[`preference-group-${preference.id}`] =
          preference.values.join(",");
      });

      try {
        const data = await ProfileService.send(params);
        window.location.reload();
      } catch (e) {
        console.log("Erro", e);
        alert("Ocorreu um problema ao salvar o perfil.");
      }
    },
    getMonth(value) {
      const month = this.months.find((el) => el.value === value);

      return month ? month.label : "";
    },
  },
  watch: {
    day: function (newValue) {
      this.form.birthday = `1970-${String(this.month).padStart(
        2,
        "0"
      )}-${String(newValue).padStart(2, "0")}`;
    },
    month: function (newValue) {
      this.form.birthday = `1970-${String(newValue).padStart(2, "0")}-${String(
        this.day
      ).padStart(2, "0")}`;
    },
  },
  mounted() {
    const selector = document.getElementById("phone");
    const im = new Inputmask("(99) 99999-9999");
    im.mask(selector);
  },
};
</script>
