<template>
  <div id="reservation-floor-list">
    <div id="reservation-floor-1" class="reservation-floor">
      <div class="scrollmenu" id="reservation-floor-list-types">
        <a
          href="javascript:;"
          v-for="type in Object.keys(types)"
          :key="type"
          class="reservation-floor-list-type"
          :class="{ active: listType === type }"
          @click="() => handleListType(type)"
          >{{ type }}</a
        >
      </div>

      <template v-for="type in Object.keys(types)">
        <ul
          :key="type"
          v-show="listType === type"
          class="reservable reservation-floor-space"
        >
          <li
            v-for="space in types[type]"
            :key="space.id"
            class="item"
            :class="{
              'reservation-list-status-full':
                getSpaceStatus(space).status === 'full',
              'reservation-list-status-partial':
                getSpaceStatus(space).status === 'partial',
              'reservation-list-status-blocked':
                getSpaceStatus(space).status === 'blocked',
              'reservation-list-status-unavailable':
                getSpaceStatus(space).status === 'unavailable',
              'reservation-list-status-group':
                getSpaceStatus(space).status === 'group',
              'reservation-list-status-full':
                getSpaceStatus(space).status === 'full',
              active: code === space.code,
            }"
            @click="handleReservation(space)"
          >
            <div class="d-flex content align-items-center">
              <div class="pl-1 pr-4">
                <strong>{{ space.code }}</strong>
              </div>

              <template v-if="getSpaceStatus(space).status === 'full'">
                <div class="w-100 pl-2 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <p>
                      <small>{{ getSpaceStatus(space).username }} </small>
                    </p>
                  </div>
                  <div
                    class="avatar rounded-circle"
                    v-if="getSpaceStatus(space).avatar"
                  >
                    <img :src="getSpaceStatus(space).avatar" />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="px-2">
                  <p>
                    <small
                      ><strong>{{ getSpaceTitle(space) }}</strong></small
                    ><br />
                    <small
                      class="floor-fullname"
                      v-if="
                        !['BLOQUEADO', 'INDISPONÍVEL'].includes(
                          getSpaceTitle(space)
                        )
                      "
                      >{{ floor.name }} -
                      {{ location.name }}</small
                    >
                  </p>
                </div>
              </template>
            </div>
          </li>
        </ul></template
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Step2List",
  props: ["types", "location", "code", "floor", "map"],
  data() {
    return {
      listType: Object.keys(this.types)[0],
    };
  },
  methods: {
    getSpaceStatus(space) {
      const status = Object.keys(this.map.status)
        .map((item) => this.map.status[item])
        .find((item) => {
          return item.code === space.code;
        });

      return status ? status : { status: "unavailable" };
    },
    getSpaceTitle(space) {
      const status = this.getSpaceStatus(space);

      let response = space.name;

      if (status.status === "blocked") {
        response = "BLOQUEADO";
      }

      if (status.status === "group") {
        response = "INDISPONÍVEL";
      }

      return response;
    },
    handleListType(newValue) {
      this.listType = newValue;
    },
    handleReservation(space) {
      const status = this.getSpaceStatus(space);

      if (!["free", "partial"].includes(status.status)) return;

      this.$emit("onReservation", { space });
    },
  },
};
</script>
