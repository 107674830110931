<template>
  <div class="where-home">
    <app-header></app-header>

    <div class="container">
      <h2 class="mt-5">
        {{ dayGreeting }},
        <span class="firstName">{{ firstName }}</span>
      </h2>
    </div>

    <template v-if="!healthy">
      <div class="container">
        <p class="mt-4" id="user-no-reservations">
          Por enquanto nós recomendamos que você fique em casa. Por isso, seu
          acesso não foi liberado.
        </p>
        <p class="mt-4" id="user-no-reservations">
          Você pode agendar reservas futuras para quando estiver apto à voltar
          ao trabalho presencial.
        </p>
      </div>
    </template>

    <template v-if="healthy">
      <template v-if="reservations.length === 0">
        <div class="container">
          <p class="mt-4" id="user-no-reservations">
            Por enquanto você não tem reservas feitas para hoje ou para os
            próximos dias.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="container">
          <small>Suas reservas</small>
        </div>
        <div class="container-md">
          <reservations-list
            :items="formattedReservations"
            :handleCancelReservation="handleCancelReservation"
            :handleSelectedReservation="handleSelectedReservation"
          />
        </div>
      </template>
    </template>

    <div class="where-home__footer">
      <div class="d-flex justify-content-between">
        <label class="switch">
          <input
            type="checkbox"
            ref="homeOfficeCheckbox"
            :checked="homeOffice"
            @input="handleHomeOfficeChange"
          />
          <span class="slider round"></span>
        </label>

        <a
          href="/users/reservations"
          class="btn btn-blue"
          data-turbolinks="false"
          >Nova reserva</a
        >
        <a
          href="/users/qrcode"
          class="btn-rose"
          id="qrcode"
          data-turbolinks="false"
        >
          <img
            :src="require('../../../../images/icon-point-dark-blue.svg')"
            height="26px"
            class="center-align"
          />
        </a>
      </div>
    </div>

    <b-modal
      id="modal-clock"
      tabindex="-1"
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      hide-header
    >
      <template #default>
        <h2>
          <strong id="reservation-code">{{
            selectedReservation.space.code
          }}</strong
          ><br />
          confirme sua reserva
        </h2>
        <p>
          Você tem até
          <u id="reservation-limit">{{ selectedReservation.checkinUntil }}</u>
          para fazer o check-in e confirmar sua reserva.
        </p>
      </template>
      <template #modal-footer="{ ok }">
        <button type="button" class="btn btn-outlined" @click="ok()">
          ENTENDI
        </button>
      </template>
    </b-modal>

    <b-modal
      id="modal-delete"
      tabindex="-1"
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      hide-header
      @ok="handleDelete"
    >
      <template #default>
        <h2>Tem certeza que deseja cancelar essa reserva?</h2>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <div class="modal-delete-actions">
          <button type="button" class="btn btn-outlined" @click="cancel()">
            Voltar
          </button>
          <button type="button" class="btn" @click="ok()">Sim, cancelar</button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-home-office"
      tabindex="-1"
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      hide-header
      @ok="handleHomeOfficeConfirmation"
      @hide="handleHomeOfficeHide"
    >
      <template #default>
        <h2>
          Ativando o trabalho remoto, suas reservas marcadas para hoje serão
          canceladas. Ok?
        </h2>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <div class="modal-delete-actions">
          <button type="button" class="btn btn-outlined" @click="cancel()">
            Voltar
          </button>
          <button type="button" class="btn" @click="ok()">
            Sim, continuar
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AppHeader from "../../components/app-header";
import ReservationsList from "../../components/reservations-list";
import HomeService from "../../services/HomeService";
export default {
  components: {
    AppHeader,
    ReservationsList,
  },
  props: {
    currentUser: Object,
    firstName: String,
    dayGreeting: String,
    healthy: Boolean,
    reservations: Array,
  },
  data() {
    return {
      cancelReservation: null,
      selectedReservation: null,
      currentDayLabel: "",
      currentDayNumber: "",
      homeOffice: this.currentUser.home_office,
    };
  },
  computed: {
    formattedReservations() {
      return this.reservations.map((reservation) => {
        return {
          ...reservation,
          shouldDisplayDay: this.shoudDisplayDay(reservation),
          shouldDisplayNumber: this.shoudDisplayNumber(reservation),
        };
      });
    },
  },
  methods: {
    handleHomeOfficeChange(event) {
      const isChecked = event.target.checked;
      if (isChecked) {
        const hasReservations = this.reservations.length > 0;
        if (hasReservations) {
          this.$bvModal.show("modal-home-office");
        } else {
          this.handleHomeOfficeConfirmation();
        }
      } else {
        HomeService.backToOffice();
      }
    },
    async handleHomeOfficeConfirmation() {
      await HomeService.deleteHomeOffice();
      window.location.reload();
    },
    handleHomeOfficeHide() {
      this.$refs.homeOfficeCheckbox.checked = false;
    },
    getReservationSelectClass(reservation) {
      return reservation.isToday
        ? "reservation-select-today"
        : "reservation-select-other-day";
    },
    shoudDisplayDay(reservation) {
      if (reservation.day === this.currentDayLabel) {
        return false;
      } else {
        this.currentDayLabel = reservation.day;
        return true;
      }
    },
    shoudDisplayNumber(reservation) {
      if (reservation.dayNumber === this.currentDayNumber) {
        return false;
      } else {
        this.currentDayNumber = reservation.dayNumber;
        return true;
      }
    },
    handleSelectedReservation(reservation) {
      this.selectedReservation = reservation;
    },
    handleCancelReservation(event, reservation) {
      this.cancelReservation = reservation;
      event.preventDefault();
    },
    async handleDelete() {
      await HomeService.delete(this.cancelReservation.id);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.where-home {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.where-home__footer {
  padding: 10px;
  margin-top: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  .d-flex {
    column-gap: 10px;
    align-items: center;
  }
}
@media (min-width: 360px) {
  .where-home__footer {
    padding: 15px;
  }
}
@media (min-width: 425px) {
  .where-home__footer {
    padding: 25px;
  }
}
@media (min-width: 48em) {
  .where-home__footer {
    left: 50%;
    max-width: 400px;
    margin-left: -200px;
  }
}
.modal-delete-actions {
  width: 100%;
  display: flex;
  column-gap: 20px;
}
</style>