import BaseService from "./BaseService";

class QrcodeService extends BaseService {
  static async send(id) {
    const axios = this.initialize();

    const request = await axios.post(`/api/users/checkin/${id}`);
    return request.data;
  }

  static async reservationStatus(id) {
    const axios = this.initialize();

    const request = await axios.post(`/api/users/reservation/${id}/status`);
    return request.data;
  }
}

export default QrcodeService;
