import BaseService from "./BaseService";

class DashboardService extends BaseService {
  static overview(payload) {
    const axios = this.initialize();

    return axios.get('/api/dashboard/overview', { params: payload });
  }

  static usage(payload) {
    const axios = this.initialize();

    return axios.get('/api/dashboard/usage', { params: payload });
  }

  static activity(payload) {
    const axios = this.initialize();

    return axios.get('/api/dashboard/activity', { params: payload });
  }

  static bookingHistory(payload) {
    const axios = this.initialize();

    return axios.get('/api/dashboard/booking_history', { params: payload });
  }

  static office(payload) {
    const axios = this.initialize();

    return axios.get('/api/dashboard/office', { params: payload });
  }

}

export default DashboardService;
