<template>
  <AdminCard title="Atividade">
    <template v-slot:subtitle>
      <AdminPeriodButton :periods="periods" @change-period="changePeriod" @change-range="changeRange" />
    </template>
    <div class="wrapper">
      <div class="block">
        <h3 class="subtitle">Check-ins</h3>
        <div class="grey-block">
          <p class="progress-bar-title">
            <strong>{{ checkins.total }}%</strong> dos usuários fizeram pelo
            menos um check-in no período selecionado
          </p>
          <ProgressBar :blue="checkins.total" />
          <div class="pie-chart">
            <Pie :chart-options="chartOptions" :chart-data="chartData" />
          </div>
        </div>
      </div>
      <div class="block">
        <h3 class="subtitle">Reservas x Check-in</h3>
        <div class="grey-block">
          <ul class="week-days">
            <li>
              <p>Domingo ({{ weekdays.sunday.total }})</p>
              <ProgressBar
                :blue="weekdays.sunday.withCheckin"
                :salmon="weekdays.sunday.withoutCheckin"
                :total="weekdays.sunday.total"
                show-inner-values
                show-count-value
              />
            </li>
            <li>
              <p>Segunda ({{ weekdays.monday.total }})</p>
              <ProgressBar
                :blue="weekdays.monday.withCheckin"
                :salmon="weekdays.monday.withoutCheckin"
                :total="weekdays.monday.total"
                show-inner-values
                show-count-value
              />
            </li>
            <li>
              <p>Terça ({{ weekdays.tuesday.total }})</p>
              <ProgressBar
                :blue="weekdays.tuesday.withCheckin"
                :salmon="weekdays.tuesday.withoutCheckin"
                :total="weekdays.tuesday.total"
                show-inner-values
                show-count-value
              />
            </li>
            <li>
              <p>Quarta ({{ weekdays.wednesday.total }})</p>
              <ProgressBar
                :blue="weekdays.wednesday.withCheckin"
                :salmon="weekdays.wednesday.withoutCheckin"
                :total="weekdays.wednesday.total"
                show-inner-values
                show-count-value
              />
            </li>
            <li>
              <p>Quinta ({{ weekdays.thursday.total }})</p>
              <ProgressBar
                :blue="weekdays.thursday.withCheckin"
                :salmon="weekdays.thursday.withoutCheckin"
                :total="weekdays.thursday.total"
                show-inner-values
                show-count-value
              />
            </li>
            <li>
              <p>Sexta ({{ weekdays.friday.total }})</p>
              <ProgressBar
                :blue="weekdays.friday.withCheckin"
                :salmon="weekdays.friday.withoutCheckin"
                :total="weekdays.friday.total"
                show-inner-values
                show-count-value
              />
            </li>
            <li>
              <p>Sábado ({{ weekdays.saturday.total }})</p>
              <ProgressBar
                :blue="weekdays.saturday.withCheckin"
                :salmon="weekdays.saturday.withoutCheckin"
                :total="weekdays.saturday.total"
                show-inner-values
                show-count-value
              />
            </li>
          </ul>
          <ul class="week-days-caption">
            <li class="week-days-caption-item">
              <span class="caption-circle blue"></span>
              <p>Reservas com check-in</p>
            </li>
            <li class="week-days-caption-item">
              <span class="caption-circle salmon"></span>
              <p>Reservas sem check-in</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </AdminCard>
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import ProgressBar from "../progress-bar";
import AdminCard from "./card";
import DashboardService from "../../services/DashboardService";
import AdminPeriodButton from "./period-button";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "AdminActivity",
  components: {
    AdminCard,
    ProgressBar,
    Pie,
    AdminPeriodButton,
  },
  data() {
    return {
      checkins: {
        chart: []
      },
      weekdays: {
        sunday: {},
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
      },
      chartData: {
        labels: [
          "Todos os dias úteis no período",
          "Em 4 dias",
          "Em 3 dias",
          "Em 2 dias",
          "Em apenas 1 dia",
          "Não fizeram check-in",
        ],
        datasets: [
          {
            backgroundColor: [
              "#001B98",
              "#A0AEE0",
              "#F1EFE4",
              "#F1ECCE",
              "#F2DEDB",
              "#E7BAAB",
            ],
            data: null,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        borderWidth: 0,
        plugins: {
          legend: {
            position: "right",
            labels: {
              usePointStyle: true,
              padding: 20,
            },
          },
          tooltip: {
            yAlign: "bottom",
            backgroundColor: "#404B5A",
            boxPadding: 5,
            usePointStyle: true,
            padding: 10,
            bodyFont: {
              size: 16,
            },
          },
        },
      },
      range: null,
      period: 'week',
      periods: [
        {
          label: "Essa semana",
          value: "week",
        },
        {
          label: "Semana passada",
          value: "last_week",
        },
        {
          label: "Esse mês",
          value: "month",
        },
        {
          label: "Mês passado",
          value: "last_month",
        },
        {
          label: "Esse ano",
          value: "year",
        },
        {
          label: "Personalizar",
          value: "custom",
        },
      ]
    };
  },
  async mounted() {
    await this.getActivity();
  },
  methods: {
    async getActivity() {
      try {
        const { data } = await DashboardService.activity({ period: this.period, ...this.range })
        this.checkins = data.checkins;
        this.weekdays = data.weekdays;
        this.chartData.datasets[0].data = this.checkins.chart;
      } catch (error) {
        console.log(error);
      }
    },
    changePeriod(period) {
      this.period = period
      this.getActivity();
    },
    changeRange(range) {
      this.period = "custom"
      this.range = range
      this.getActivity();
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}

.block {
  width: 100%;
}

.subtitle {
  color: #404b5a;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  margin-top: 24px;
}

.grey-block {
  background: #f7f7f7;
  border-radius: 8px;
  margin-top: 16px;
  padding: 16px;
}

.progress-bar-title {
  color: #404b5a;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px !important;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;

  strong {
    font-size: 24px;
    line-height: 28px;
    color: #404b5a;
  }
}

.week-days {
  padding: 0;

  li {
    display: flex;
    column-gap: 32px;
    align-items: center;

    .where-progress-bar {
      width: 100%;
      border-radius: 12px;
      overflow: hidden;

      ::v-deep div {
        line-height: 24px;
        height: 24px;

        &.salmon {
          width: 100% !important;
        }
      }
    }
    p {
      color: #404b5a;
      font-size: 12px;
      line-height: 16px;
      min-width: 80px;
      margin: 0 !important;
    }
    list-style-type: none;
    margin-bottom: 16px;
  }
}

.week-days-caption {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 0;
  padding: 0;
  margin-top: 18px;
}

.week-days-caption-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  list-style-type: none;

  p {
    margin: 0 !important;
    color: #82818c;
    font-size: 12px;
    line-height: 1;
  }
}

.caption-circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50px;
  flex-shrink: 0;

  &.blue {
    background: #001b98;
  }

  &.yellow {
    background: #f1ecce;
  }

  &.salmon {
    background: #e7baab;
  }
}

.pie-chart {
  margin-top: 25px;
}
</style>
