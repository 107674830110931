import Axios from "axios";

class BaseService {
  static initialize() {
    const token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    const axios = Axios.create({
      headers: {
        "X-CSRF-Token": token,
        Accept: "application/json",
      },
      maxRedirects: 0,
    });

    return axios;
  }
}

export default BaseService;
