<template>
  <div class="app app-responsive overflow-auto">
    <form @submit.prevent="handleSubmit">
      <div class="container">
        <div class="row mb-5 company-logo">
          <div
            v-if="currentCompanyLogo"
            class="col-md-12 d-flex justify-content-center mt-5"
          >
            <img class="customer-logo" :src="currentCompanyLogo" />
          </div>
          <div class="col-md-12 d-flex justify-content-center mt-2">
            <img
              height="18px"
              style="padding-top: 0.4rem; padding-right: 0.4rem"
              id="powered-by"
              :src="require('../../../../images/powered-by.svg')"
            />
            <img
              height="18px"
              id="where-logo"
              :src="require('../../../../images/logo-where.svg')"
            />
          </div>
        </div>

        <div class="alert_login mb-2">
          {{ alert }}
        </div>

        <div class="form-login">
          <div class="row">
            <div class="col-md-12">
              <h1 class="mb-3 desk text-white">Recuperar senha</h1>
              <p class="desk text-white mb-5">
                Vamos te enviar um link para cadastrar uma nova senha.
              </p>
            </div>
          </div>

          <div class="form">
            <div class="form-group">
              <label for="email">Digite seu e-mail</label>
              <input
                type="email"
                name="email"
                v-model="form.email"
                id="email"
                required="required"
                class="form-control login"
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="footer">
            <button type="submit" class="btn" id="submit">Enviar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AppHeader from "../../components/app-header";
import RecoverService from "../../services/RecoverService";

export default {
  components: {
    AppHeader,
  },
  props: {
    currentCompanyLogo: String,
  },
  data() {
    return {
      alert: "",
      form: {
        email: "",
      },
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const request = await RecoverService.send(this.form);

        this.form.email = "";

        if (request.message === "sucesso") {
          this.alert = "Nós enviamos um link para o seu e-mail.";
        } else {
          this.alert = request.message;
        }
      } catch (e) {
        this.form.email = "";
        this.alert = "Ocorreu um erro na requisição.";
      }
    },
  },
};
</script>
