<template>
  <div>
    <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      :width="width"
      :height="height"
      viewBox="0 0 22 22"
      fill="none"
      version="1.1"
      id="svg4"
      sodipodi:docname="Ellipse 27.svg"
      inkscape:version="0.92.5 (2060ec1f9f, 2020-04-08)">
      <metadata
        id="metadata10">
        <rdf:RDF>
          <cc:Work
            rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type
              rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <defs
        id="defs8" />
      <sodipodi:namedview
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1"
        objecttolerance="10"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-width="1920"
        inkscape:window-height="1016"
        id="namedview6"
        showgrid="false"
        inkscape:zoom="19.800561"
        inkscape:cx="-8.7133644"
        inkscape:cy="7.3503181"
        inkscape:window-x="1920"
        inkscape:window-y="27"
        inkscape:window-maximized="1"
        inkscape:current-layer="svg4" />
      <path
        inkscape:connector-curvature="0"
        :style="`fill:${fill};stroke:${fill};stroke-width:0.5`"
        d="M 10.5,6 H 9 v 6 l 5.25,3.15 0.75,-1.23 -4.5,-2.67 z"
        id="path12" />
      <circle
        cx="11"
        cy="11"
        r="10"
        :stroke="fill"
        stroke-width="1.7"
        id="circle2" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'WhereClockIcon',
  props: {
    fill: {
      type: String,
      default: '#151223',
    },
    height: {
      type: String,
      default: '22',
    },
    width: {
      type: String,
      default: '22',
    },
  },
}
</script>