import BaseService from "./BaseService";

class ProfileService extends BaseService {
  static async send(params) {
    const axios = this.initialize();

    const request = await axios.post(`/api/preferences`, params);
    return request.data;
  }
}

export default ProfileService;
