<template>
  <div class="container where-full-height">
    <div class="d-flex flex-column where-full-height">
      <div class="pt-3">
        <a href="/users/menu" class="menu-icon">
          <img
            height="12px"
            :src="require('../../../../images/arrow_white.svg')"
          />
        </a>
      </div>

      <carousel
        class="where-carousel"
        :autoplay="true"
        :autoplayTimeout="6000"
        :perPage="1"
      >
        <slide>
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <img
              class="d-block"
              width="299px"
              alt="First slide"
              :src="require('../../../../images/saiba_mais01.png')"
            />
            <div class="where-carousel-text text-center pt-4">
              <h2>Minhas reservas</h2>
              <p>
                Escolha os dias e reserve locais de trabalho, salas de reunião e
                os períodos de home office.
              </p>
            </div>
          </div>
        </slide>
        <slide>
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <img
              class="d-block"
              width="299px"
              alt="Second slide"
              :src="require('../../../../images/saiba_mais02.png')"
            />
            <div class="where-carousel-text text-center pt-5">
              <h2 class="pt-4">Check-in no local</h2>
              <p>
                Use QR Codes espalhados no escritório para fazer check-in e
                ocupar um local de trabalho.
              </p>
            </div>
          </div>
        </slide>
        <slide>
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <img
              class="d-block"
              width="299px"
              alt="First slide"
              :src="require('../../../../images/saiba_mais03.png')"
            />
            <div class="where-carousel-text text-center pt-5">
              <h2>Busca e favoritos</h2>
              <p>
                Encontre pessoas e locais, salve nos favoritos e siga as
                atividades do seu time.
              </p>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  props: {},
};
</script>

<style>
.where-carousel-text {
  max-width: 240px;
}

.where-full-height {
  height: 100%;
}

.where-carousel {
  flex: 1;
  justify-content: center;
}

.where-carousel img {
  max-width: 100%;
}

.where-carousel .VueCarousel-dot {
  padding: 0 !important;
  margin: 5px !important;
  background: transparent !important;
  border: 2px solid rgba(255, 255, 255, 0.5);
  width: 6px !important;
  height: 6px !important;
  transition: all 0.25s ease-in;
}
.where-carousel .VueCarousel-dot--active {
  width: 16px !important;
  background: #fff !important;
  border-radius: 4px;
}
</style>
