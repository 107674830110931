<template>
  <div>
    <app-header kind="back"></app-header>

    <div class="container">
      <div class="row no-gutters mt-5">
        <div class="col-10 pl-4">
          <input
            type="text"
            class="form-control search"
            placeholder="Buscar"
            v-model="searchTerm"
          />
        </div>
        <div class="col-2 pl-3">
          <img
            :src="require('../../../../images/lupa.svg')"
            height="18px"
            class="mt-2"
          />
        </div>
      </div>

      <div v-if="searchTerm === ''" class="row mt-4" id="resource-search-desc">
        <div class="col-12">
          <p class="px-4">
            Encontre pessoas, estações de trabalho, salas de reunião e vários
            outros itens de reserva.
          </p>
        </div>
      </div>

      <div
        v-if="searchTerm !== '' && items.length === 0"
        class="row mt-4"
        id="resource-search-desc"
      >
        <div class="col-12">
          <p class="px-4">Nenhum resultado para "{{ searchTerm }}".</p>
        </div>
      </div>

      <ul id="resource-search-result">
        <li v-if="spaces.length > 0" class="resource-search-type">
          Estação de trabalho
        </li>
        <template v-for="(item, index) in spaces">
          <li :key="index" class="resource-search-result-item-space">
            <span>{{ item.code }}</span>
            <span>{{ item.name }}</span>
            <span>{{ item.location }}</span>
            <img
              @click="handleFavorited(item)"
              v-bind:class="{
                'resource-search-favorite-selected': favorites.includes(
                  item.id
                ),
                'resource-search-favorite': !favorites.includes(item.id),
              }"
            />
          </li>
        </template>
        <li v-if="people.length > 0" class="resource-search-type">Pessoas</li>
        <template v-for="(item, index) in people">
          <li :key="index" class="resource-search-result-item-user">
            <img
              :src="item.avatar"
              class="resource-search-user-avatar rounded-circle"
            />
            <span
              class="user-status"
              v-bind:class="{
                'status-green': item.available,
                'status-red': !item.available,
              }"
            />
            <span>{{ item.name }}</span>
            <img
              @click="handleFavorited(item)"
              v-bind:class="{
                'resource-search-favorite-selected': favorites.includes(
                  item.id
                ),
                'resource-search-favorite': !favorites.includes(item.id),
              }"
            />
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import AppHeader from "../../components/app-header";
import SearchService from "../../services/SearchService";
import _ from "lodash";

export default {
  components: {
    AppHeader,
  },
  props: {
    currentUser: Object,
    firstName: String,
    isAvailable: Boolean,
    avatar: String,
    isFirefighter: Boolean,
    hasSSOProvider: Boolean,
    isAdmin: Boolean,
    isSuperAdmin: Boolean,
    admin_home_path: String,
    super_home_path: String,
  },
  data() {
    return {
      searchTerm: "",
      favorites: [],
      items: [],
    };
  },
  computed: {
    people() {
      return this.items.filter((item) => item.type === "User");
    },
    spaces() {
      return this.items.filter((item) => item.type === "Space");
    },
  },
  methods: {
    handleFavorited: async function (item) {
      if (this.favorites.includes(item.id)) {
        this.favorites = this.favorites.filter((el) => el !== item.id);
        await SearchService.unfavorite(item);
      } else {
        this.favorites = [...this.favorites, item.id];
        await SearchService.favorite(item);
      }
    },
  },
  watch: {
    searchTerm: _.debounce(async function (newValue) {
      const request = await SearchService.submit(newValue);
      this.items = request;
      this.favorites = this.items
        .filter((el) => el.favorited)
        .map((el) => el.id);
    }, 500),
  },
};
</script>

<style lang="scss">
.app {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
