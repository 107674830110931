import BaseService from "./BaseService";

class MenuService extends BaseService {
  static async upload(content) {
    const axios = this.initialize();

    const request = await axios.patch("/api/users/avatar", { avatar: content });
    return request.data;
  }

  static async available(checked) {
    const axios = this.initialize();

    const request = await axios.patch("/api/users/available", {
      available: checked ? 1 : 0,
    });
    return request.data;
  }
}

export default MenuService;
