<template>
  <div class="container where-reservations-step-3">
    <div class="d-flex flex-column align-items-center mt-2 mb-1">
      <h2 class="mt-2">{{ space.name }}</h2>
      <span>{{ capacity }}</span>
    </div>

    <div class="where-reservations-step-3-form mt-3">
      <h6>HORÁRIOS DE RESERVAS</h6>
      <WhereTimePicker ref="from" keyValue="from" label="Início" :valid="fromIsValid" :errorMessage="errorMessages.from" @updateValue="updateValue"/>
      <WhereTimePicker ref="to" keyValue="to" label="Fim" :valid="toIsValid" :errorMessage="errorMessages.to" @updateValue="updateValue"/>

      <div class="time" @click="openDurationModal">
        <div class="duration">
          <h6>
            <WhereClockIcon width="9" height="24" class="mr-1" />
            Duração:
          </h6>
          <span>{{ max_duration_text }}</span>
        </div>
        <span>{{ duration_text }}</span>
      </div>
    </div>

    <div class="where-reservations-step-3-reservations mt-3 mb-4">
      <h6>HORÁRIOS OCUPADOS</h6>
      <div v-for="(reservation, index) in reservations" :key="index" class="ocupation">
        <div class="reservation">
          <div>
            <img class="reservation-avatar" :src="reservation.user_avatar" alt="Avatar">
            <span>{{ reservation.user_name }}</span>
          </div>
          <span>{{ reservation.starts_at }} às {{ reservation.finishes_at }}</span>
        </div>
        <div class="interval mt-1" v-if="reservation.interval > 0">
          <span>{{ interval_label(reservation.interval) }}</span>
          <span>{{ interval_range(reservation) }}</span>
        </div>
      </div>
    </div>

    <WhereDurationPicker :currentValue="reservation.duration" @changeDuration="changeDuration" v-if="showDurationModal"/>
  </div>
</template>

<script>
import spaceService from "../../services/SpaceService";
import WhereDurationPicker from "./../../components/where-duration-picker";
import WhereClockIcon from "./../../components/icons/WhereClockIcon";
import WhereCircleIcon from "./../../components/icons/WhereCircleIcon";
import WhereTimePicker from "./../../components/where-time-picker";

import { format, parseISO, addMinutes, areIntervalsOverlapping, isWithinInterval, intervalToDuration, isPast } from "date-fns";

export default {
  props: ["slots", "currentDate"],
  components: { WhereDurationPicker, WhereClockIcon, WhereCircleIcon, WhereTimePicker },
  data() {
    return {
      showDurationModal: false,
      height: "",
      selected: "",
      reservation: {
        from: "",
        to: "",
        duration: "",
      },
      space: {
        name: "",
        capacity: 0,
        duration: 0,
        reservations: []
      },
      error: null,
    };
  },
  mounted() {
    this.getSpaceData()
  },
  methods: {
    openDurationModal() {
      if (!this.reservation.from) return;

      this.showDurationModal = true;
    },
    changeDuration(duration) {
      this.showDurationModal = false
      if (!duration) return;

      if (duration === 1) {
        this.$refs.from.setFirstAvailableHour()
        this.$nextTick(() => {
          this.reservation.from = `${this.$refs.from.hours[0]}:${this.$refs.from.minutes[0]}`;
          this.reservation.to = this.space.close_at;

          this.updateValue({ key: 'from', value: this.reservation.from});
          this.updateValue({ key: 'to', value: this.space.close_at});

          this.$refs.from.updateValueFromDuration(this.reservation.from);
          this.$refs.to.updateValueFromDuration(this.space.close_at);
        })
        return;
      }

      const newEndHour = format(addMinutes(this.convertStringHourToValidDate(this.reservation.from), duration), "HH:mm");
      this.updateValue({ key: 'to', value: newEndHour});
      this.$refs.to.updateValueFromDuration(newEndHour);
    },
    updateValue(payload) {
      this.reservation[payload.key] = payload.value;
      this.$emit('handleSlot', { from: this.reservation.from, to: this.reservation.to });
    },
    async getSpaceData() {
      try {
        this.space = await spaceService.spaceById(this.getSpaceId());

        const currentDate = format(this.$parent.currentDate, "yyyy-MM-dd");
        const reservationData = await spaceService.reservationsByDate(this.getSpaceId(), currentDate);

        this.$emit('openingForReservation', this.space)
        this.$set(this.space, 'reservations', reservationData)
      } catch (error) {
        this.error = error
      }
    },
    getSpaceId() {
      return this.$parent.space_id;
    },
    interval_label(interval) {
      const time = interval == 1 ? 'minuto' : 'minutos'

      return `Intervalo ${interval} ${time}`
    },
    interval_range(reservation) {
      let [hour, minute] = reservation.finishes_at.split(":")

      let interval_finishes_at = new Date(2000, 1, 1, hour, minute)
      interval_finishes_at = format(addMinutes(interval_finishes_at, reservation.interval), "HH:mm")

      return `${reservation.finishes_at} às ${interval_finishes_at}`
    },
    convertStringHourToValidDate(date){
      if (!date) return null

      let [hour, minute] = date.split(":")
      return new Date(2000, 1, 1, hour, minute)
    },
    validateHour(date){
      if (!this.isIntervalWithinAnotherInterval.length) return true

      let index = date == 'from' ? 0 : this.isIntervalWithinAnotherInterval.length - 1
      const convertedDate = this.convertStringHourToValidDate(this.reservation[date])
      const interval = this.isIntervalWithinAnotherInterval[index]

      return !isWithinInterval(convertedDate, interval)
    },
    isWithinAValidInterval(key) {
      if (!this.reservation[key]) return false;
      const date = this.convertStringHourToValidDate(this.reservation[key])

      return isWithinInterval(date, this.currentInterval)
    },
  },
  computed: {
    errorMessages() {
      let from = ''
      let to = ''

      if (!this.fromIsValid || !this.isIntervalWithinAnotherInterval) from = 'Horário indisponível'
      if (!this.toIsValid || !this.isIntervalWithinAnotherInterval) to = 'Horário indisponível'

      if (this.isIntervalOverlapping.length) {
        from = 'Período indisponível'
        to = 'Período indisponível'
      }

      if (!this.durationIsValid) {
        from = 'Duração inválida'
        to = 'Duração inválida'
      }

      return { from, to }
    },
    reservationDuration() {
      if (this.reservation.from == "" || this.reservation.to == "") return {}

      let start = this.convertStringHourToValidDate(this.reservation.from)
      let end = this.convertStringHourToValidDate(this.reservation.to)

      if (!start && !end) return {}

      return intervalToDuration({start, end})
    },
    durationIsValid() {
      if (this.reservation.from == "" || this.reservation.to == "") return true

      const duration = this.reservationDuration
      return (duration.hours * 60 + duration.minutes) <= this.space.duration
    },
    fromIsValid() {
      return this.validateHour('from') && this.durationIsValid && !this.isIntervalOverlapping.length
    },
    toIsValid() {
      return this.validateHour('to') && this.durationIsValid && !this.isIntervalOverlapping.length
    },
    duration_text() {
      if (!this.reservationDuration.minutes && !this.reservationDuration.hours) return 'Selecione'

      let label = ''
      let hour = ''

      if (this.reservationDuration.hours > 0) {
        hour = 'hora' + (this.reservationDuration.hours > 1 ? 's' : '')
        label += `${this.reservationDuration.hours} ${hour} `
      }

      if (this.reservationDuration.minutes > 0) {
        hour = 'minuto' + (this.reservationDuration.minutes > 1 ? 's' : '')
        label += `${this.reservationDuration.minutes} ${hour}`
      }

      return label
    },
    capacity() {
      if (this.space.capacity < 2) return ''

      return `Até ${this.space.capacity} pessoas`
    },
    max_duration_text() {
      if (this.space.duration == 0) return ''

      const start = new Date();
      const end = addMinutes(start, this.space.duration)
      const duration = intervalToDuration({start, end})

      let label = ''
      let hour = ''

      if (duration.hours > 0) {
        hour = 'hora' + (duration.hours > 1 ? 's' : '')
        label += `${duration.hours} ${hour} `
      }

      if (duration.minutes > 0) {
        hour = 'minuto' + (duration.minutes > 1 ? 's' : '')
        label += `${duration.minutes} ${hour}`
      }

      return `Máximo: ${label}`
    },
    reservations() {
      if (!this.space.reservations || !this.space.reservations.length) return [];

      let reservations = [];
      this.space.reservations.filter(reservation => {
        reservations.push({
          starts_at: format(parseISO(reservation.starts_at), 'HH:mm'),
          finishes_at: format(parseISO(reservation.finishes_at), 'HH:mm'),
          user_name: reservation.user_name,
          user_avatar: reservation.user_avatar_url,
          interval: reservation.interval,
        })
      })

      return reservations
    },
    occupationIntervals() {
      if (!this.reservations.length) return [];
      let occupation = [];

      this.reservations.filter(reservation => {
        let start = this.convertStringHourToValidDate(reservation.starts_at)
        let end = this.convertStringHourToValidDate(reservation.finishes_at)
        end.setSeconds(end.getSeconds() - 1)

        if (occupation.length) {
          occupation.filter((interval) => {
            const isInAnyInterval = isWithinInterval(start, interval) || isWithinInterval(end, interval)
            const isOverlappingAnyInterval = areIntervalsOverlapping({ start, end }, interval)

            if (isOverlappingAnyInterval || isInAnyInterval) interval.overlap ++
          })
        }

        occupation.push({ start, end: addMinutes(end, reservation.interval), overlap: 0 })
      })

      return occupation
    },
    isIntervalWithinAnotherInterval() {
      if (this.reservation.from == '' && this.reservation.to == '') return [];

      let reservations = []
      let currentInterval = Object.assign({}, this.currentInterval)
      currentInterval.overlap = 0

      this.occupationIntervals.filter(reservation => {
        if (isWithinInterval(currentInterval.start, reservation) || isWithinInterval(currentInterval.end, reservation)) {
          currentInterval.overlap ++
          reservations.push(reservation)
        }
      })

      return reservations.filter(reservation => {
        if (!this.space.shareable) return reservation
        if (currentInterval.overlap + 1 > this.space.capacity) return reservation
      })
    },
    isIntervalOverlapping() {
      if (this.reservation.from == '' || this.reservation.to == '') return [];

    	let reservations = []
      let currentInterval = Object.assign({}, this.currentInterval)
      currentInterval.overlap = 0

      this.occupationIntervals.filter(reservation => {
        if (areIntervalsOverlapping(currentInterval, reservation)) {
          currentInterval.overlap ++
          reservations.push(reservation)
        }
      })

      return reservations.filter(reservation => {
        if (!this.space.shareable) return reservation
        if (currentInterval.overlap + 1 > this.space.capacity) return reservation
      })
    },
    currentInterval() {
      let from_date, to_date = null;

      if (this.reservation.from != ''){
        let [from_hour, from_minute] = this.reservation.from.split(":")
        from_date = new Date(2000, 1, 1, from_hour, from_minute)
      }

      if (this.reservation.to != ''){
        let [to_hour, to_minute] = this.reservation.to.split(":")
        to_date = new Date(2000, 1, 1, to_hour, to_minute)
      }

      return { start: from_date, end: to_date }
    },
    isReservationValid() {
      return !!(this.reservation.from && this.fromIsValid && this.reservation.to && this.toIsValid && this.durationIsValid && !this.isIntervalOverlapping.length)
    },
  },
  watch: {
    isReservationValid() {
      this.$emit('validateStepThree', this.isReservationValid)
    },
    '$parent.currentDate'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getSpaceData()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-outlined-blue {
  background-color: #001b98!important;
  color: #fff!important;
}
.where-reservations-step-3 {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.where-reservations-step-3-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    width: 80%;
    margin: 0;
    font-size: 12px;
  }
}

.duration {
  display: flex;
  flex-direction: column;

  span {
    font-size: 10px;
  }

  h6 > img {
    max-height: 8px;
  }
}

.where-reservations-step-3-reservations {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  .ocupation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  h6 {
    width: 80%;
    margin: 0;
    font-size: 12px;
  }
}

.reservation {
  font-size: 14px;

  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  background: #F1ECCE;
  border-radius: 8px;
  margin-top: 0.5rem;

  img {
    height: 32px;
  }
}

.interval {
  font-size: 10px;

  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 16px;

  background: #F0F0ED;
  border-radius: 4px;
}

.reservation-avatar {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.where-reservations-step-3-reservations {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    width: 80%;
    margin: 0;
    font-size: 12px;
  }
}

.reservation {
  font-size: 14px;

  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  background: #F1ECCE;
  border-radius: 8px;
  margin-top: 0.5rem;

  img {
    height: 32px;
  }
}

.interval {
  font-size: 10px;

  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 16px;

  background: #F0F0ED;
  border-radius: 4px;
}

.reservation-avatar {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}
</style>
