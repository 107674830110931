var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"reservation-floor-list"}},[_c('div',{staticClass:"reservation-floor",attrs:{"id":"reservation-floor-1"}},[_c('div',{staticClass:"scrollmenu",attrs:{"id":"reservation-floor-list-types"}},_vm._l((Object.keys(_vm.types)),function(type){return _c('a',{key:type,staticClass:"reservation-floor-list-type",class:{ active: _vm.listType === type },attrs:{"href":"javascript:;"},on:{"click":function () { return _vm.handleListType(type); }}},[_vm._v(_vm._s(type))])}),0),_vm._v(" "),_vm._l((Object.keys(_vm.types)),function(type){return [_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.listType === type),expression:"listType === type"}],key:type,staticClass:"reservable reservation-floor-space"},_vm._l((_vm.types[type]),function(space){return _c('li',{key:space.id,staticClass:"item",class:{
            'reservation-list-status-full':
              _vm.getSpaceStatus(space).status === 'full',
            'reservation-list-status-partial':
              _vm.getSpaceStatus(space).status === 'partial',
            'reservation-list-status-blocked':
              _vm.getSpaceStatus(space).status === 'blocked',
            'reservation-list-status-unavailable':
              _vm.getSpaceStatus(space).status === 'unavailable',
            'reservation-list-status-group':
              _vm.getSpaceStatus(space).status === 'group',
            'reservation-list-status-full':
              _vm.getSpaceStatus(space).status === 'full',
            active: _vm.code === space.code,
          },on:{"click":function($event){return _vm.handleReservation(space)}}},[_c('div',{staticClass:"d-flex content align-items-center"},[_c('div',{staticClass:"pl-1 pr-4"},[_c('strong',[_vm._v(_vm._s(space.code))])]),_vm._v(" "),(_vm.getSpaceStatus(space).status === 'full')?[_c('div',{staticClass:"w-100 pl-2 d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',[_c('small',[_vm._v(_vm._s(_vm.getSpaceStatus(space).username)+" ")])])]),_vm._v(" "),(_vm.getSpaceStatus(space).avatar)?_c('div',{staticClass:"avatar rounded-circle"},[_c('img',{attrs:{"src":_vm.getSpaceStatus(space).avatar}})]):_vm._e()])]:[_c('div',{staticClass:"px-2"},[_c('p',[_c('small',[_c('strong',[_vm._v(_vm._s(_vm.getSpaceTitle(space)))])]),_c('br'),_vm._v(" "),(
                      !['BLOQUEADO', 'INDISPONÍVEL'].includes(
                        _vm.getSpaceTitle(space)
                      )
                    )?_c('small',{staticClass:"floor-fullname"},[_vm._v(_vm._s(_vm.floor.name)+" -\n                    "+_vm._s(_vm.location.name))]):_vm._e()])])]],2)])}),0)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }