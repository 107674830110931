var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-header',{attrs:{"kind":"back"}}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row no-gutters mt-5"},[_c('div',{staticClass:"col-10 pl-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"form-control search",attrs:{"type":"text","placeholder":"Buscar"},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"col-2 pl-3"},[_c('img',{staticClass:"mt-2",attrs:{"src":require('../../../../images/lupa.svg'),"height":"18px"}})])]),_vm._v(" "),(_vm.searchTerm === '')?_c('div',{staticClass:"row mt-4",attrs:{"id":"resource-search-desc"}},[_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.searchTerm !== '' && _vm.items.length === 0)?_c('div',{staticClass:"row mt-4",attrs:{"id":"resource-search-desc"}},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"px-4"},[_vm._v("Nenhum resultado para \""+_vm._s(_vm.searchTerm)+"\".")])])]):_vm._e(),_vm._v(" "),_c('ul',{attrs:{"id":"resource-search-result"}},[(_vm.spaces.length > 0)?_c('li',{staticClass:"resource-search-type"},[_vm._v("\n        Estação de trabalho\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.spaces),function(item,index){return [_c('li',{key:index,staticClass:"resource-search-result-item-space"},[_c('span',[_vm._v(_vm._s(item.code))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.location))]),_vm._v(" "),_c('img',{class:{
              'resource-search-favorite-selected': _vm.favorites.includes(
                item.id
              ),
              'resource-search-favorite': !_vm.favorites.includes(item.id),
            },on:{"click":function($event){return _vm.handleFavorited(item)}}})])]}),_vm._v(" "),(_vm.people.length > 0)?_c('li',{staticClass:"resource-search-type"},[_vm._v("Pessoas")]):_vm._e(),_vm._v(" "),_vm._l((_vm.people),function(item,index){return [_c('li',{key:index,staticClass:"resource-search-result-item-user"},[_c('img',{staticClass:"resource-search-user-avatar rounded-circle",attrs:{"src":item.avatar}}),_vm._v(" "),_c('span',{staticClass:"user-status",class:{
              'status-green': item.available,
              'status-red': !item.available,
            }}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('img',{class:{
              'resource-search-favorite-selected': _vm.favorites.includes(
                item.id
              ),
              'resource-search-favorite': !_vm.favorites.includes(item.id),
            },on:{"click":function($event){return _vm.handleFavorited(item)}}})])]})],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('p',{staticClass:"px-4"},[_vm._v("\n          Encontre pessoas, estações de trabalho, salas de reunião e vários\n          outros itens de reserva.\n        ")])])}]

export { render, staticRenderFns }