<template>
  <div>
    <app-header kind="close"></app-header>

    <div class="container">
      <div class="row mt-2 border-bottom pb-3 mb-2">
        <div class="col-3 div-avatar">
          <input
            type="file"
            name="avatar_input"
            id="avatar_input"
            accept="image/jpeg"
            @change="handleAvatarUpload"
          />
          <input type="hidden" name="avatar" id="avatar" />
          <img
            height="25px"
            class="icon-edit user-avatar-click"
            id="user-avatar-click"
            @click="handleAvatarClick"
            :src="require('../../../../images/icon_edit_rounded.svg')"
          />

          <div class="avatar rounded-circle">
            <img
              id="img-avatar"
              class="user-avatar-click"
              :src="avatar || require('../../../../images/avatar.svg')"
            />
          </div>
        </div>

        <div class="col-9 pt-4 user_info">
          <h2>{{ firstName }}</h2>
          <span class="brigadist" v-if="isFirefighter">
            <img
              :src="require('../../../../images/fire.svg')"
              height="14px"
              class="ml-1 mb-1"
            />
            BRIGADISTA</span
          >
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-12 status d-flex">
          <div>
            <p class="mb-0">
              <small>STATUS</small><br />
              <span id="reservation-status-text">{{
                form.isAvailable ? "Disponível" : "Indisponível"
              }}</span>
            </p>
          </div>

          <label class="switch ml-auto mt-4">
            <input
              type="checkbox"
              id="user-available"
              v-model="form.isAvailable"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="row mb-4 status">
        <div class="col-md-12">
          <div class="user-menu">
            <ul>
              <li><a href="/users/profile">Perfil</a></li>
              <li v-if="!hasSSOProvider">
                <a href="/users/update_password">Atualizar senha</a>
              </li>
              <li v-if="isAdmin || isSuperAdmin">
                <a :href="admin_home_path">Administrador</a>
              </li>
              <li v-if="isSuperAdmin">
                <a :href="super_home_path">Super Administrador</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <a
                class="btn btn-outlined"
                rel="nofollow"
                data-method="delete"
                href="/sessions/0"
                >Sair do Where</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../../components/app-header";
import MenuService from "../../services/MenuService";

export default {
  components: {
    AppHeader,
  },
  props: {
    currentUser: Object,
    firstName: String,
    isAvailable: Boolean,
    avatar: String,
    isFirefighter: Boolean,
    hasSSOProvider: Boolean,
    isAdmin: Boolean,
    isSuperAdmin: Boolean,
    admin_home_path: String,
    super_home_path: String,
  },
  data() {
    return {
      form: {
        isAvailable: this.isAvailable,
      },
    };
  },
  methods: {
    handleAvatarClick() {
      document.querySelector("#avatar_input").click();
    },
    handleAvatarUpload(event) {
      event.preventDefault();

      const resize = new ImageResize();
      resize.resize(
        "avatar_input",
        "img-avatar",
        "avatar",
        250,
        250,
        function (content) {
          MenuService.upload(content);
        }
      );
    },
  },
  watch: {
    isAvailable: function (newValue) {
      this.form.isAvailable = newValue;
    },
    "form.isAvailable": function (newValue) {
      MenuService.available(newValue);
    },
  },
};
</script>
