<template>
  <div class="row mb-4">
    <div class="col-sm-12">
      <div class="card white-card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <hgroup class="hgroup">
                <h3>{{ title }}</h3>
                <h4 v-if="subtitle">{{ subtitle }}</h4>
                <slot v-else name="subtitle"></slot>
              </hgroup>
              <slot v-if="!noBody"></slot>
            </div>
          </div>
        </div>
        <slot v-if="noBody"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminCard",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    noBody: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.hgroup {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: #82818c;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
    text-transform: none;
  }
}
</style>
