<template>
  <div class="where-reservations-step-2">
    <div
      v-if="viewMode === 'map' && !loading && !error"
      class="where-reservations-step-2__control"
    >
      <button class="zoom-btn zoom-btn-1" @click="handleZoomIn">+</button>
      <button class="zoom-btn zoom-btn-2" @click="handleZoomOut">-</button>
    </div>
    <div
      v-if="viewMode === 'map' && !loading && !error"
      class="where-reservations-step-2__map"
      v-html="render"
    ></div>
    <div class="h-100" v-if="loading">
      <div
        class="
          h-100
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
      >
        <b-spinner />
        <div class="__text">Carregando...</div>
      </div>
    </div>
    <div class="h-100" v-if="!loading && error">
      <div
        class="
          h-100
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
      >
        Oops, houve um erro, tente novamente!
        <b-button @click="step2Data()">Recarregar mapa</b-button>
      </div>
    </div>
    <template v-if="viewMode === 'list'">
      <step-2-list
        :types="types"
        :floor="floor"
        :map="map"
        :location="location"
        :code="code"
        @onReservation="onReservation"
      />
    </template>
  </div>
</template>

<script>
import svgPanZoom from "svg-pan-zoom";
import Step2List from "./step2/list";
import Hammer from "hammerjs";
import ReservationService from "../../services/ReservationService";
import { format } from "date-fns";

export default {
  components: { Step2List },
  props: ["viewMode", "code"],
  data() {
    return {
      loading: false,
      error: false,
      zoom: null,
      floor: null,
      render: "",
      map: "",
      types: "",
      location: { name: "" },
    };
  },
  async mounted() {
    await this.step2Data();
  },
  methods: {
    async loadMap() {
      const ref = this;
      const selector = "path, text, rect";
      let time;

      if (!this.$parent.mapHasLoaded) {
        $("body").on("touchstart", selector, function (evt) {
          time = 0;
        });
        $("body").on("touchmove", selector, function (evt) {
          time = time + 1;
        });
        $("body").on("touchend", selector, function (evt) {
          if (time < 15) {
            ref.handlePlace(evt);
          }
        });
        $("body").on("click", selector, function (evt) {
          ref.handlePlace(evt);
        });
        this.$emit("loadMap");
      }

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.space_id && params.code && params.floor_id) {
        let spaceElement = document.getElementById(this.code);
        $(spaceElement).click((evt) => this.handlePlace(evt));
        $(spaceElement).click();
        this.zoomAt();
      }
    },
    zoomAt() {
      var tViewport = document.querySelector("g.svg-pan-zoom_viewport");
      var tMatrix = tViewport.transform.baseVal.getItem(0).matrix;
      var tBBox = document.querySelector(`#${this.code}`).getBBox();
      var tPoint = {
        x: (tBBox.x + tBBox.width / 2) * tMatrix.a + tMatrix.e,
        y: (tBBox.y + tBBox.height / 2) * tMatrix.d + tMatrix.f,
      };

      tPoint.x = tPoint.x < 20 ? 0 : 20;
      this.zoom.reset();
      this.zoom.zoomAtPoint(6, tPoint);
    },
    async step2Data() {
      try {
        this.loading = true;
        this.error = false;
        const reponse = await ReservationService.getFloorMap({
          ...this.params,
        });
        this.render = reponse.data;
        const { data } = await ReservationService.step2({ ...this.params });
        this.loading = false;
        this.error = false;
        this.floor = data.floor;
        this.types = data.types;
        this.location = data.location;
        this.map = data.map;

        this.$nextTick(async () => {
          await this.initializeZoom();
          await this.loadMap();
        });
      } catch (error) {
        this.error = true;
        this.loading = null;
      }
    },
    async initializeZoom() {
      const svg = document.querySelector(".where-reservations-step-2 svg");

      if (!svg) return;

      const zoom = svgPanZoom(svg, {
        zoomEnabled: true,
        zoomScaleSensitivity: 0.5,
        controlIconsEnabled: false,
        contain: true,
        customEventsHandler: {
          haltEventListeners: ["touchmove", "touchleave", "touchcancel"],
          init: function (options) {
            var instance = options.instance,
              initialScale = 1,
              pannedX = 0,
              pannedY = 0;

            // Init Hammer
            // Listen only for pointer and touch events
            this.hammer = Hammer(options.svgElement, {
              inputClass: Hammer.SUPPORT_POINTER_EVENTS
                ? Hammer.PointerEventInput
                : Hammer.TouchInput,
            });

            // Enable pinch
            this.hammer.get("pinch").set({ enable: true });

            // Handle double tap
            this.hammer.on("doubletap", function (ev) {
              instance.zoomIn();
            });

            // Handle pan
            this.hammer.on("panstart panmove", function (ev) {
              // On pan start reset panned variables
              if (ev.type === "panstart") {
                pannedX = 0;
                pannedY = 0;
              }

              // Pan only the difference
              instance.panBy({
                x: ev.deltaX - pannedX,
                y: ev.deltaY - pannedY,
              });
              pannedX = ev.deltaX;
              pannedY = ev.deltaY;
            });

            // Handle pinch
            this.hammer.on("pinchstart pinchmove", function (ev) {
              // On pinch start remember initial zoom
              if (ev.type === "pinchstart") {
                initialScale = instance.getZoom();
                instance.zoomAtPoint(initialScale * ev.scale, {
                  x: ev.center.x,
                  y: ev.center.y,
                });
              }

              instance.zoomAtPoint(initialScale * ev.scale, {
                x: ev.center.x,
                y: ev.center.y,
              });
            });

            // Prevent moving the page on some devices when panning over SVG
            options.svgElement.addEventListener("touchmove", function (e) {
              if (e.cancelable) e.preventDefault();
            });
          },

          destroy: function () {
            this.hammer.destroy();
          },
        },
      });

      zoom.zoom(4);
      zoom.center();
      this.zoom = zoom;
    },
    handlePlace(evt) {
      let element = $(evt.target);
      let group = $(element).closest("g");

      if ($(group).attr("id").includes("icon")) {
        group = $(group).parent("g");
      }

      const hasNumeracao = $("body").find("#numeracao").length > 0;
      const belongsToNumeracao = $(group).parent("#numeracao").length > 0;
      const id = $(group).attr("id");
      const text = $(group).find("tspan");

      const isValid =
        (hasNumeracao ? belongsToNumeracao : true) || text.text() > 0;

      if (!isValid) return;

      const path = $(group).find("path, rect")[0];
      const className = $(path).attr("class");
      let user;

      if (className && className.match(/unavailable/)) {
        user = parseInt($(path).attr("data-user"));
        if (!isNaN(user)) {
          this.$emit("showUserData", { user, id });
        }
        return;
      }

      if (className && className.match(/blocked/)) {
        return;
      }

      if (className && className.match(/group/)) {
        if (!this.floor) return;

        const code = $(group).attr("id");

        this.$emit("showGroups", { floor: this.floor.id, code });
        return;
      }

      const isAllowed =
        $(path).attr("fill") === "white" ||
        (className && className.match(/partial/));

      if (!isAllowed) return;

      this.activateSlot(path, text, group);
    },
    activateSlot(path, text, group) {
      let cls = "reservation-selected-spot";
      let clst = "reservation-selected-spot-text";
      let exist = $(path).hasClass(cls);

      $("path, rect").removeClass(cls).removeClass(clst);
      $("tspan").removeClass(clst);

      if (!exist) {
        $(path).addClass(cls);
        if (text) {
          $(text).addClass(clst);
          $(group).find("g path").addClass(clst);
        }
      } else {
        $(path).removeClass(cls);
        if (text) {
          $(text).removeClass(clst);
          $(group).find("g path").removeClass(clst);
        }
      }
    },
    handleZoomIn() {
      this.zoom.zoomIn();
    },
    handleZoomOut() {
      this.zoom.zoomOut();
    },
    onReservation({ space }) {
      this.$emit("onReservation", { space });
    },
  },
  computed: {
    params() {
      return {
        floor_id: this.$parent.floor.id,
        date: format(this.$parent.currentDate, "yyyy-MM-dd"),
      };
    },
  },
  watch: {
    "$parent.currentDate"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.step2Data();
      }
    },
    loading(newValue, oldValue) {
      if (newValue !== oldValue) this.$emit("loading", newValue);
    },
    error(newValue, oldValue) {
      if (newValue === true) this.$emit("loading", true);
    },
  },
};
</script>

<style>
.where-reservations-step-2 {
  width: 100%;
  height: calc(100% - 40px);
  margin-top: 20px;
  position: relative;
}

.where-reservations-step-2__control {
  position: absolute;
  right: 35px;
  top: 15px;
  display: flex;
  flex-direction: column;
}

.zoom-btn {
  background: #fff;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  text-align: center;
}

.zoom-btn-1 {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #ccc;
}

.zoom-btn-2 {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.where-reservations-step-2__map {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}

.where-reservations-step-2__map svg {
  width: 100%;
  height: 100%;
}
</style>
