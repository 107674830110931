export default {
  methods: {
    getQueryParams() {
      return new URLSearchParams(window.location.search);
    },
    getQueryParamsAsObject() {
      return Object.fromEntries(this.getQueryParams().entries());
    },
  },
};
