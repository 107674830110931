<template>
  <div class="time" :class="inputValidationProps.inputClass"  @click="openModal()">
    <h6> <WhereCircleIcon :fill="inputValidationProps.circleColor" /> {{ label }}: </h6>
    <div class="d-flex flex-column align-items-end">
      <span>
        {{ value || 'Selecione' }}
        <WhereClockIcon v-if="inputValidationProps.invalid" fill="#D96969" width="13" height="13" class="ml-1"/>
      </span>
      <span v-if="inputValidationProps.invalid" class="invalid-span"> {{ errorMessage }} </span>
    </div>

    <b-modal :id="`modal-${keyValue}`" hide-header no-close-on-esc no-close-on-backdrop @hide="showScrollPicker = false">
      <template #default v-if="showScrollPicker">
        <div class="where-timer-picker">
          <scroll-picker-group class="mt-4">
            <div class="d-flex justify-content-center">
              <div class="mr-3">
                <span class="title mb-5">Horas</span>
                <scroll-picker :options="hours" v-model="hour"/>
              </div>
              <div>
                <span class="title">Minutos</span>
                <scroll-picker :options="minutes" v-model="minute"/>
              </div>
            </div>
          </scroll-picker-group>
        </div>
      </template>
      <template #modal-footer>
        <button type="button" class="btn btn-outlined" @click="cancel()">
          CANCELAR
        </button>
        <button type="button" class="btn btn-outlined btn-outlined-blue" @click="updateValue()">
          OK
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import WhereClockIcon from "./icons/WhereClockIcon";
import WhereCircleIcon from "./icons/WhereCircleIcon";
import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker"
import { isToday, isFuture } from 'date-fns'
import "vue-scroll-picker/dist/style.css"

export default {
  props: {
    valid: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    keyValue: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  components: { WhereClockIcon, WhereCircleIcon, ScrollPicker, ScrollPickerGroup },
  data() {
    return {
      value: "",
      valueModal: "",
      hour: '8',
      minute: '00',
      showScrollPicker: false
    }
  },
  methods: {
    updateValue() {
      this.value = `${this.hour}:${this.minute}`
      this.$emit("updateValue", { value: this.value, key: this.keyValue });
      this.$bvModal.hide(`modal-${this.keyValue}`);
    },
    cancel() {
      this.$bvModal.hide(`modal-${this.keyValue}`);
    },
    openModal() {
      this.$bvModal.show(`modal-${this.keyValue}`);
      setTimeout(() => {
        this.showScrollPicker = true;
      }, 50);
    },
    updateValueFromDuration(newValue) {
      const [hour, minute] = newValue.split(":")

      this.value = newValue
      this.hour = hour
      this.minute = minute
    },
    setFirstAvailableHour(){
      if (this.keyValue == 'from') this.hour = this.hours[0]
    }
  },
  computed: {
    minutes() {
      let minutes = []
      const open_at_hour = this.$parent.space.open_at.split(":")[0]
      const open_at_minute = this.$parent.space.open_at.split(":")[1]
      const close_at_hour = this.$parent.space.close_at.split(":")[0]
      const close_at_minute = this.$parent.space.close_at.split(":")[1]
      const currentDate = new Date()
      const currentHour = currentDate.getHours()
      const currentMinute = currentDate.getMinutes()
      const reservationFrom = this.$parent.reservation.from
      const today = isToday(this.$parent.currentDate)

      for (let i = 0; i < 60; i++) {
        if (i % 5 === 0) {
          if (parseInt(this.hour) == parseInt(open_at_hour) && i <= open_at_minute && today) continue;
          if (parseInt(this.hour) == parseInt(close_at_hour) && i > close_at_minute) continue;
          if (parseInt(this.hour) == currentHour && i < currentMinute && today) continue;
          if (this.keyValue == 'to' && reservationFrom && i <= reservationFrom.split(":")[1] && this.hour == reservationFrom.split(":")[0]) continue;

          minutes.push(`${i < 10 ? "0" : ""}${i}`)
        }
      }

      return minutes
    },
    hours() {
      let hours = []
      const open_at = this.$parent.space.open_at.split(":")[0]
      const close_at = this.$parent.space.close_at.split(":")[0]
      const currentHour = new Date().getHours()
      const currentMinute = new Date().getMinutes()
      const today = isToday(this.$parent.currentDate)
      const isInSpaceWorkRange = (i) => i >= parseInt(open_at) && i <= parseInt(close_at)
      const reservationFrom = this.$parent.reservation.from

      for (let i = 0; i < 24; i++) {
        if (today) {
          if (!isInSpaceWorkRange(i) && i > currentHour || i < currentHour) continue;
        } else {
          if (!isInSpaceWorkRange(i)) continue;
        }

        if (this.keyValue == 'to' && reservationFrom && i < reservationFrom.split(":")[0]) continue;
        if (this.keyValue == 'to' && reservationFrom && i == reservationFrom.split(":")[0] && reservationFrom.split(":")[1] == "55") continue;
        if (currentMinute >= 55 && i == currentHour) continue;

        hours.push(`${i}`);
      }
      return hours
    },
    inputValidationProps() {
      let obj = {
        inputClass: '',
        circleColor: '#BBBBBB',
        invalid: false
      }

      if (this.value == "") return { ...obj }

      if (this.valid === true) {
        obj.inputClass = 'time-valid';
        obj.circleColor = '#88E8B0';
        obj.invalid = false
      } else if (this.valid === false) {
        obj.inputClass = 'time-invalid';
        obj.circleColor = '#D96969';
        obj.invalid = true
      }

      return { ...obj }
    }
  }
};
</script>

<style lang="scss">
.title {
  font-size: 12px!important;
}

.where-timer-picker {
  .vue-scroll-picker {
    height: 13em;


    .vue-scroll-picker-list {
      .vue-scroll-picker-list-rotator {
        font-weight: bold;
        font-size: 24px;
        color: #82818C;

        .vue-scroll-picker-item {
          height: 4rem;
          color: #82818C!important;

          &.-selected {
            color: #001B98!important;

            &::after {
              content: '';
              border-bottom: 3px solid #001B98;
              width: 100%;
              display: block;
            }
          }
        }
      }
    }

    .vue-scroll-picker-layer {
      .top {
        border-bottom: none;
        height: calc(30% - 1em);
      }
      .middle {
        top: calc(40% - 1em);
      }
      .bottom {
        border-top: none;
        height: calc(47% - 1em);
      }
    }
  }
}

.time {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  background: #FFFFFF;
  border-radius: 8px;
  margin-top: 0.5rem;

  h6 {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 16px;
    margin: 0;

    svg {
      margin-right: 5px;
    }
  }
}

.time-invalid {
  h6 {
    svg {
      circle {
        fill: #D96969;
      }
    }
  }

  span {
    display: flex;
    font-size: 14px;
    color: #D96969;
  }

  .invalid-span {
    font-size: 10px;
    white-space: nowrap;
  }
}

.time-valid {
  h6 {
    svg {
      circle {
        fill: #88E8B0;
      }
    }
  }
}
</style>
