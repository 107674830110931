<template>
  <div class="where-reservations-index">
    <div>
      <app-header
        kind="back"
        :handleBack="handleBack"
        :showSearch="![2, 3, 4].includes(step)"
      >
        <template v-if="step !== 4" slot>
          <span id="reservation-date" class="bg-white shadow-sm px-4 py-2">
            <a href="javascript:;" id="reservation-calendar">
              <span id="reservation-label"></span>
              <span id="reservation-days">{{ currentDay }}</span>
              <span id="reservation-date-click"></span>
            </a>
          </span>
          <button
            @click="handleViewMode"
            class="where-view-button"
            v-if="step === 2"
          >
            <img
              :src="
                require(`../../../../images/${
                  viewMode === 'list' ? 'icon_map' : 'icon_menu'
                }.svg`)
              "
              height="19px"
            />
          </button>
          <span v-if="step === 3"></span>
        </template>
        <template v-else slot>
          <a href="/users/home" class="menu-icon">
            <img :src="require('../../../../images/close.svg')" height="12px" />
          </a>
        </template>
      </app-header>
      <div
        id="calendar"
        :data-year="selectedDateAsObject.year"
        :data-month="selectedDateAsObject.month"
        :data-day="selectedDateAsObject.day"
        :data-selected="selectedDate"
        :data-opening-for-reservation="space.opening_for_reservation"
      >
        <div class="calendar_inner">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-8 mx-auto">
                <table class="table">
                  <caption>
                    <span id="calendar_cur"></span>
                    <span id="calendar_nav" class="float-right shadow-sm">
                      <span id="calendar_prev">
                        <img
                          :src="
                            require('../../../../images/arrow_left_full.svg')
                          "
                          height="10px"
                          class="mr-2"
                        />
                      </span>
                      <span id="calendar_month"></span>
                      <span id="calendar_next">
                        <img
                          :src="
                            require('../../../../images/arrow_right_full.svg')
                          "
                          height="10px"
                          class="ml-2"
                      /></span>
                    </span>
                  </caption>
                  <thead>
                    <tr>
                      <th>Dom</th>
                      <th>Seg</th>
                      <th>Ter</th>
                      <th>Qua</th>
                      <th>Qui</th>
                      <th>Sex</th>
                      <th>Sab</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>

                <div class="buttons-calendar">
                  <a
                    id="calendar-cancel"
                    href="javascript:;"
                    class="btn btn-outlined"
                    >Cancelar</a
                  >
                  <a
                    id="calendar-select"
                    @click="selectDate"
                    class="btn btn-blue"
                    >Selecionar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="where-steps">
      <step-1
        v-if="step === 1"
        @setFloor="setFloor"
        @setLocation="setLocation"
      />
      <step-2
        v-if="step === 2"
        :code="code"
        :viewMode="viewMode"
        @onReservation="onReservation"
        @loadMap="loadMap"
        v-on:showUserData="showUserData"
        v-on:showGroups="showGroups"
        @loading="loadingStep2"
        ref="step2"
      />
      <step-3
        v-if="step === 3"
        :slots="slots"
        :currentDate="currentDate"
        @handleSlot="handleSlot"
        @validateStepThree="validateStepThree"
        @openingForReservation="openingForReservation"
      />
      <step-4
        v-if="step === 4"
        :code="code"
        :currentDate="currentDate"
        :floor="floor"
        :startAt="startAt"
        :finishesAt="finishesAt"
        :slots="slots"
        @moreDaysReservation="moreDaysReservation"
      />
    </div>

    <div class="container d-flex justify-content-center align-items-center">
      <button
        class="btn btn-default"
        type="button"
        @click="handleNext"
        :disabled="!canHandleNext"
      >
        {{ step === 4 ? "Confirmar" : "Avançar" }}
      </button>
    </div>

    <b-modal id="modal-full" hide-header>
      <template #default>
        <h2>
          <strong id="reservation-code">{{ code }}</strong
          ><br />
          indisponível
        </h2>
        <p>Todos os períodos já foram reservados para esse dia</p>
      </template>
      <template #modal-footer="{ ok }">
        <button type="button" class="btn btn-outlined" @click="ok()">
          FECHAR
        </button>
      </template>
    </b-modal>

    <b-modal id="modal-error" hide-header>
      <template #default>
        <h2>
          <strong id="reservation-code">{{ code }}</strong>
          <br />
          Indisponível
        </h2>
        <p>{{ reservationError }}</p>
      </template>
      <template #modal-footer="{ ok }">
        <button
          type="button"
          class="btn btn-outlined"
          @click="handlerError(ok)"
        >
          FECHAR
        </button>
        <a
          id="reservation-error-logout"
          class="btn btn-outlined hidden"
          rel="nofollow"
          data-method="delete"
          href="/sessions/0"
          style="display: none"
          >Sair do Where</a
        >
      </template>
    </b-modal>

    <b-modal id="reservation-group" hide-header>
      <template #default>
        <h2>
          <strong id="reservation-group-code">{{ code }}</strong
          ><br />
          indisponível
        </h2>
        <p>
          Apenas os colaboradores dos grupos
          <span
            id="reservation-group-groups"
            style="text-decoration: underline"
            >{{ groups }}</span
          >
          podem fazer essa reserva.
        </p>
      </template>
      <template #modal-footer="{ ok }">
        <button type="button" class="btn btn-outlined" @click="ok()">
          FECHAR
        </button>
      </template>
    </b-modal>

    <b-modal id="reservation-user-data" hide-header>
      <template #default>
        <h2 class="mb-3">
          <strong id="reservation-user-code">{{ code }}</strong
          ><br />reservado por
        </h2>
        <div class="d-flex flex-row">
          <div class="avatar rounded-circle">
            <img :src="user.avatar" id="reservation-user-img-avatar" />
          </div>

          <div class="pl-3 user_info">
            <span
              class="user-status rounded"
              id="reservation-user-status"
              :class="{
                'status-red': !user.available,
                'status-green': user.available,
              }"
              >&nbsp;</span
            >
            <h2 id="reservation-user-name">{{ user.name }}</h2>
            <p class="where-reservation-actions">
              <small
                v-if="user.phone"
                class="shadow-sm p-3 bg-white rounded mr-2"
                style="border-radius: 0.8rem !important; text-transform: none"
                >Ligar
                <a :href="`tel:${user.phone}`" id="reservation-user-phone">{{
                  user.phone
                }}</a></small
              >
              <small
                class="shadow-sm p-3 bg-white rounded"
                style="border-radius: 0.8rem !important"
                ><a
                  :href="`mailto:${user.email}`"
                  id="reservation-user-email"
                  target="_blank"
                >
                  <img
                    :src="require('../../../../images/email.svg')"
                    width="25px" /></a
              ></small>
            </p>
            <p class="mb-0" v-if="user.birthday">
              <span id="reservation-user-birthday"
                ><img
                  :src="require('../../../../images/cake.svg')"
                  height="14px"
                  class="ml-1 mb-1"
                />
                <small>ANIVERSARIANTE</small></span
              >
            </p>
            <p v-if="user.firefighter">
              <span id="reservation-user-firefighter"
                ><img
                  :src="require('../../../../images/fire.svg')"
                  height="14px"
                  class="ml-1 mb-1"
                />
                <small>BRIGADISTA</small></span
              >
            </p>
          </div>
        </div>
      </template>
      <template #modal-footer="{ ok }">
        <button type="button" class="btn btn-outlined" @click="ok()">
          FECHAR
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { format, isToday, isValid, parse } from "date-fns";
import AppHeader from "../../components/app-header.vue";
import Step1 from "./step1.vue";
import Step2 from "./step2.vue";
import Step3 from "./step3.vue";
import Step4 from "./step4.vue";
import Calendar from "../../../calendar";
import ReservationService from "../../services/ReservationService";
import SpaceService from "../../services/SpaceService";

export default {
  components: { AppHeader, Step1, Step2, Step3, Step4 },
  data() {
    return {
      loadStep2: false,
      mapHasLoaded: false,
      reservationError: null,
      calendar: {},
      step: 1,
      canGoToStepFour: false,
      currentDate: new Date(),
      location: {},
      floor: {},
      space_id: null,
      code: "",
      user: {},
      groups: "",
      slots: [],
      startAt: "",
      finishesAt: "",
      viewMode: "map",
      space: {
        opening_for_reservation: 0,
      },
      reservationDates: [],
    };
  },
  mounted() {
    this.setCalendar();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.space_id && params.code && params.floor_id) {
      this.handleFirstStep();
      this.space_id = params.space_id;
      this.code = params.code;
      this.floor.id = params.floor_id;
      this.handleSecondStep();
    }
  },
  methods: {
    loadingStep2(value) {
      this.loadStep2 = value;
    },
    handlerError(ok) {
      if (this.reservationError === "Token inválido ou expirado") {
        document.getElementById("reservation-error-logout").click();
      } else {
        ok();
      }
    },
    loadMap() {
      this.mapHasLoaded = true;
    },
    setFloor(floor) {
      this.floor = floor;
    },
    setLocation(location) {
      this.location = location;
    },
    selectDate() {
      const calendar = document.getElementById("calendar");
      let selectedDate = calendar.dataset.selected.split("-");

      if (selectedDate) {
        const parsedDate = parse(
          `${selectedDate[0]}-${("0" + (parseInt(selectedDate[1]) + 1)).slice(
            -2
          )}-${("0" + selectedDate[2]).slice(-2)}`,
          "yyyy-MM-dd",
          new Date()
        );
        if (isValid(parsedDate)) {
          this.currentDate = parsedDate;
        }
      }
    },
    moreDaysReservation(dates) {
      this.reservationDates = dates;
    },
    setCalendar() {
      setTimeout(function () {
        try {
          window.calendar = new Calendar();
          window.calendar.attach();
        } catch (e) {
          console.log(e);
        }
      });
    },
    openingForReservation(space) {
      let calendarElement = document.getElementById("calendar");
      calendarElement.dataset.openingForReservation =
        space.opening_for_reservation;
      setTimeout(function () {
        window.calendar.next();
        window.calendar.prev();
      }, 50);
    },
    handleViewMode() {
      this.viewMode = this.viewMode === "map" ? "list" : "map";
    },
    handleSlot({ from, to }) {
      this.startAt = from;
      this.finishesAt = to;
    },
    onReservation({ space }) {
      this.code = space.code;
    },
    validateStepThree(valid) {
      this.canGoToStepFour = valid;
    },
    handleFirstStep() {
      this.step = 2;
    },
    async handleSecondStep() {
      let code;

      if (this.viewMode === "map") {
        const spot = $(".reservation-selected-spot");

        if (spot.length === 0) return;

        code = spot.parent("g").attr("id");
        this.code = code;
      } else {
        if (!this.code) return;

        code = this.code;
      }

      const date = format(this.currentDate, "yyyy-MM-dd");

      try {
        const slots = await ReservationService.slotStatus({
          code,
          date,
          floor: this.floor.id,
        });
        this.slots = slots;

        const { flexible_booking, id } =
          await SpaceService.spaceByCodeAndFloorId(code, this.floor.id);
        this.space_id = id;
        this.space.flexible_booking = flexible_booking;

        if (!flexible_booking) {
          this.startAt = slots[0].from;
          this.finishesAt = slots[0].to;
          const now = new Date();

          if (date === format(now, "yyyy-MM-dd")) {
            let hour = now.getHours();
            let minute = now.getMinutes();
            hour = hour.length > 1 ? hour : `0${hour}`;
            minute = minute.length > 1 ? minute : `0${minute}`;
            this.startAt = `${hour}:${minute}`;
          }

          this.step = 4;
        } else {
          this.step = 3;
        }
      } catch (e) {
        this.$bvModal.show("modal-full");
      }
    },
    async handleThirdStep() {
      this.step = 4;
    },
    async handleFourthStep() {
      try {
        const { data } = await ReservationService.create({
          dates: this.formatedReservationDates,
          floor_id: this.floor.id,
          code: this.code,
          space_code: this.code,
          starts_at: this.startAt,
          finishes_at: this.finishesAt,
        });
        window.location.href = data.url;
      } catch (error) {
        this.reservationError = error.response.data.message;
        this.$bvModal.show("modal-error");
      }
    },
    async showUserData({ user, id }) {
      this.code = id;
      try {
        const response = await ReservationService.getUser({ user });
        this.user = response;
        this.$bvModal.show("reservation-user-data");
      } catch (e) {
        alert("Falha ao carregar os dados do usuário.");
      }
    },
    async showGroups({ floor, code }) {
      try {
        const response = await ReservationService.getGroups({
          floor_id: floor,
          code,
        });
        this.groups = response.string;
        this.code = code;
        this.$bvModal.show("reservation-group");
      } catch (e) {
        alert("Falha ao carregar os dados do grupo.");
      }
    },
    async handleBack() {
      if (this.step === 1) {
        window.location.href = "/users/home";
        return;
      }

      if (this.step === 4 && !this.space.flexible_booking) {
        return (this.step = 2);
      }

      this.step = this.step - 1;

      if (this.step === 2) {
        return await this.handleFirstStep();
      }
    },
    async handleNext() {
      if (this.step === 1) {
        await this.handleFirstStep();
        return;
      }

      if (this.step === 2) {
        await this.handleSecondStep();
        return;
      }

      if (this.step === 3) {
        await this.handleThirdStep();
        return;
      }

      if (this.step === 4) {
        await this.handleFourthStep();
        return;
      }
    },
  },
  computed: {
    selectedDateAsObject() {
      const date = this.currentDate;

      return {
        year: format(date, "yyyy"),
        month: Number(format(date, "MM")) - 1,
        day: Number(format(date, "dd")),
      };
    },
    selectedDate() {
      return `${format(this.currentDate, "yyyy")}-${
        format(this.currentDate, "MM") - 1
      }-${format(this.currentDate, "dd")}`;
    },
    currentDay() {
      if (isToday(this.currentDate)) {
        return "Hoje";
      }

      return format(this.currentDate, "dd/MM");
    },
    canHandleNext() {
      if (this.step == 2 && this.loadStep2) return false;
      if (this.step != 3) return true;

      return this.canGoToStepFour;
    },
    formatedReservationDates() {
      let newDates = [];
      let dates = [format(this.currentDate, "dd/MM/yyyy")];
      dates.push(...this.reservationDates);

      dates.filter((date) => {
        const formatedDate = parse(date, "dd/MM/yyyy", new Date());
        newDates.push(format(formatedDate, "yyyy-MM-dd"));
      });

      return newDates;
    },
  },
  watch: {
    isFinished(newValue) {
      if (newValue) {
        this.handleFinish();
      }
    },
  },
};
</script>

<style lang="scss">
.where-reservations-index {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

body#reservations-body .btn-default {
  margin-bottom: 10px;
}

.where-steps {
  height: -webkit-fill-available;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

#reservation-user-img-avatar {
  width: 100%;
  height: auto;
}

.where-reservations-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  .btn {
    max-width: 1140px;
    margin: 0 auto;
    display: block;
  }
}

.where-reservation-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}

@media (min-width: 375px) {
  .where-reservation-actions {
    flex-direction: row;
  }
}

.where-view-button {
  width: auto;
  background: #fff;
  padding: 0.6rem 0.6rem;
}
</style>
