<template>
  <div>
    <AdminOverview />
    <AdminUsage />
    <AdminActivity />
    <AdminBookingHistory />
    <AdminOffice />
  </div>
</template>

<script>
import AdminOverview from "../../components/admin/overview.vue";
import AdminUsage from "../../components/admin/usage.vue";
import AdminActivity from "../../components/admin/activity.vue";
import AdminBookingHistory from "../../components/admin/booking-history.vue";
import AdminOffice from "../../components/admin/office.vue";

export default {
  name: "AdminHome",
  components: {
    AdminOverview,
    AdminUsage,
    AdminActivity,
    AdminBookingHistory,
    AdminOffice,
  },
};
</script>
