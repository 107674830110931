<template>
  <div>
    <div v-if="finish" class="where-finish">
      <div id="reservation-header">
        <p class="text-right my-4">
          <a href="/users/home">
            <img height="12px" :src="require('../../../../images/close.svg')" />
          </a>
        </p>
      </div>

      <template v-if="response.notice === 'Formulário salvo'">
        <h2 class="my-5">
          <span class="firstName">{{ firstName }}</span
          >,
          <template v-if="response.form.result"
            >seu acesso está liberado.</template
          >
        </h2>

        <p v-if="response.form.result">
          {{ response.question_form.positive_answer }}
        </p>
        <p v-else>
          {{ response.question_form.negative_answer }}
        </p>
      </template>
      <template v-else>
        <h2 class="my-5">
          <span class="firstName">{{ firstName }}</span
          >, ocorreu um erro ao salvar o formulário.
        </h2>

        <p>Tente mais tarde.</p>
      </template>

      <div class="footer">
        <div class="container">
          <a
            href="/users/home"
            class="btn btn-default quest-form-action-button"
            style="background: black; color: white"
          >
            Finalizar
          </a>
        </div>
      </div>
    </div>

    <form v-if="!finish" id="question-form-form">
      <div id="question-form">
        <div v-if="!start" class="question-form question-form-start">
          <div class="top-link">
            <small
              ><a class="question-form-action" @click="handleBack">{{
                form.name
              }}</a></small
            >
          </div>
          <div class="first-text px-3 mt-5">
            <h1>
              <span>{{ firstName }}</span
              >, tudo bem?
            </h1>
            <p>{{ form.text }}</p>
          </div>
          <div class="footer">
            <div class="container">
              <button
                name="button"
                type="submit"
                class="
                  question-form-action question-form-action-button
                  btn-default
                "
                @click="handleStart"
              >
                Começar
              </button>
            </div>
          </div>
        </div>

        <template v-if="start">
          <div
            class="question-form where-question-form hfill"
            v-for="(question, index) in form.questions"
            :key="question.id"
            :class="{ 'where-question-form--visible': position === index }"
          >
            <div class="top-link">
              <small
                ><a
                  class="question-form-action return"
                  href="javascript:;"
                  @click="handleBack"
                  >{{ form.name }}</a
                ></small
              >
            </div>
            <div class="px-3 mt-5">
              <p class="question-form-text-card">
                {{ question.text }}
              </p>

              <ul class="question-form-options question-form-card mt-5">
                <li
                  v-for="(choice, index) in question.choicesList"
                  :key="index"
                  class="question-form-option question-form-option-card"
                  :class="{
                    'question-form-option-enabled': choice === selectedChoice,
                  }"
                  @click="handleChoice(choice)"
                >
                  {{ choice }}
                </li>
              </ul>
            </div>

            <div class="footer-scroll">
              <div class="container">
                <div class="d-flex justify-content-end">
                  <span class="cont"
                    >{{ currentPosition }}/{{ totalQuestions }}</span
                  >
                  <button
                    type="button"
                    @click="handleNext"
                    class="
                      btn_next
                      question-form-action question-form-action-button
                    "
                    :class="{
                      'disabled-question-form-button': choiceIsEmpty,
                    }"
                    :disabled="choiceIsEmpty"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import FormService from "../../services/FormService";

export default {
  props: {
    currentUser: Object,
    firstName: String,
    form: Object,
  },
  data() {
    return {
      start: false,
      finish: false,
      position: 0,
      selectedChoice: "",
      selectedChoices: [],
      response: {},
    };
  },
  computed: {
    currentPosition() {
      return this.position + 1;
    },
    totalQuestions() {
      return this.form.questions.length;
    },
    choiceIsEmpty() {
      return this.selectedChoice === "";
    },
    isValid() {
      const isInvalid = this.form.questions.some((question, index) => {
        const invalidAnswer = question.invalidAnswer;
        const userAnswer = this.selectedChoices[index];

        return userAnswer === invalidAnswer;
      });

      return !isInvalid;
    },
    isFinished() {
      return this.currentPosition > this.totalQuestions;
    },
  },
  methods: {
    handleStart() {
      this.start = true;
    },
    handleChoice(choice) {
      this.selectedChoice = choice;
    },
    handleNext() {
      this.selectedChoices[this.position] = this.selectedChoice;
      this.selectedChoice = "";
      this.position = this.position + 1;
    },
    handleBack() {
      if (this.position === 0) {
        this.start = false;
      }

      if (this.position > 0) {
        this.position = this.position - 1;
        this.selectedChoice = this.selectedChoices[this.position];
      }
    },
  },
  watch: {
    async isFinished(newValue) {
      const request = await FormService.submit({
        form: this.form,
        choices: this.selectedChoices,
        isValid: this.isValid,
      });

      this.finish = true;
      this.response = request;
    },
  },
};
</script>

<style>
.where-question-form {
  display: none;
}
.where-question-form--visible {
  display: block;
}
#preferences-body .app .where-finish h2 {
  font-size: 1.5rem;
}
</style>
