<template>
  <AdminCard title="Escritório">
    <p class="subtitle">Uso dos espaços nesse momento</p>

    <div class="wrapper">
      <div class="header">
        <ul class="spaces-list">
          <li class="spaces-list-item" v-for="space in spaces" :key="space.id">
            <button
              :class="{ active: currentSpace === space.id }"
              @click="currentSpace = space.id"
            >
              {{ space.name }}
            </button>
          </li>
        </ul>

        <select v-model="currentFloor" class="floors-list">
          <option value="">Selecione o piso</option>
          <option :value="floor.id" v-for="floor in floors" :key="floor.id">
            {{ floor.name }}
          </option>
        </select>
      </div>

      <div class="content" v-if="currentFloor">
        <div class="control">
          <button @click="handleZoomIn">+</button>
          <button @click="handleZoomOut">-</button>
        </div>
        <div v-html="svg"></div>
      </div>
    </div>
  </AdminCard>
</template>

<script>
import AdminCard from "./card";
import svgPanZoom from "svg-pan-zoom";
import Hammer from "hammerjs";
import DashboardService from "../../services/DashboardService";

export default {
  name: "AdminOffice",
  components: {
    AdminCard,
  },
  data: () => ({
    spaces: [],
    currentSpace: "",
    currentFloor: "",
    zoom: null,
  }),
  async mounted() {
    await this.getOffice();
  },
  methods: {
    async getOffice() {
      try {
        const { data } = await DashboardService.office()
        this.spaces = data;
        if (this.spaces.length > 0) this.currentSpace = this.spaces[0].id;
      } catch (error) {
        console.log(error);
      }
    },
    handleZoomIn() {
      this.zoom.zoomIn();
    },
    handleZoomOut() {
      this.zoom.zoomOut();
    },
  },
  computed: {
    floors() {
      const space = this.spaces.find((item) => item.id === this.currentSpace);

      if (space) return space.floors;

      return [];
    },
    svg() {
      const floor = this.floors.find((item) => item.id === this.currentFloor);

      if (floor) return floor.svg;

      return "";
    },
  },
  watch: {
    currentSpace() {
      if (this.floors.length > 0) {
        this.currentFloor = this.floors[0].id;
      }
    },
    svg: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          const svg = document.querySelector("svg");

          if (!svg) return;
          const zoom = svgPanZoom(svg, {
            zoomEnabled: true,
            zoomScaleSensitivity: 0.5,
            controlIconsEnabled: false,
            contain: true,
            customEventsHandler: {
              haltEventListeners: ["touchmove", "touchleave", "touchcancel"],
              init: function (options) {
                var instance = options.instance,
                  initialScale = 1,
                  pannedX = 0,
                  pannedY = 0;

                // Init Hammer
                // Listen only for pointer and touch events
                this.hammer = Hammer(options.svgElement, {
                  inputClass: Hammer.SUPPORT_POINTER_EVENTS
                    ? Hammer.PointerEventInput
                    : Hammer.TouchInput,
                });

                // Enable pinch
                this.hammer.get("pinch").set({ enable: true });

                // Handle double tap
                this.hammer.on("doubletap", function (ev) {
                  instance.zoomIn();
                });

                // Handle pan
                this.hammer.on("panstart panmove", function (ev) {
                  // On pan start reset panned variables
                  if (ev.type === "panstart") {
                    pannedX = 0;
                    pannedY = 0;
                  }

                  // Pan only the difference
                  instance.panBy({
                    x: ev.deltaX - pannedX,
                    y: ev.deltaY - pannedY,
                  });
                  pannedX = ev.deltaX;
                  pannedY = ev.deltaY;
                });

                // Handle pinch
                this.hammer.on("pinchstart pinchmove", function (ev) {
                  // On pinch start remember initial zoom
                  if (ev.type === "pinchstart") {
                    initialScale = instance.getZoom();
                    instance.zoomAtPoint(initialScale * ev.scale, {
                      x: ev.center.x,
                      y: ev.center.y,
                    });
                  }

                  instance.zoomAtPoint(initialScale * ev.scale, {
                    x: ev.center.x,
                    y: ev.center.y,
                  });
                });

                // Prevent moving the page on some devices when panning over SVG
                options.svgElement.addEventListener("touchmove", function (e) {
                  e.preventDefault();
                });
              },

              destroy: function () {
                this.hammer.destroy();
              },
            },
          });

          zoom.zoom(4);
          zoom.center();
          this.zoom = zoom;
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  display: block;
  background-color: white;
  padding-left: .75rem;
  padding-right: .75rem;
  color: #82818C;
  border: 1px solid #bacad6;
  border-radius: .5rem;
  height: 2.25rem;
  line-height: normal;
  width: 100%;
  font-size: 1rem;
}
.subtitle {
  color: #82818c;
  font-size: 12px;
  line-height: 16px;
}

.header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.spaces-list {
  width: 100%;
  list-style-type: none;
  display: flex;
  column-gap: 10px;
  padding: 0;

  .spaces-list-item {
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: #404b5a;
      font-size: 14px;
      line-height: 18px;
      padding: 8px 12px;
      padding-bottom: 16px;
      box-shadow: none;
      border-radius: 0;

      &.active {
        color: #001b98;
        border-bottom: 2px solid #001b98;
      }
    }
  }
}

.floors-list {
  width: 100%;
  max-width: 244px;
}

.content {
  margin-top: 20px;
  background: #f7f7f7;
  position: relative;

  &::v-deep svg {
    width: 100%;
    max-height: 500px;
  }
}

.control {
  position: absolute;
  right: 35px;
  top: 15px;
  display: flex;
  flex-direction: column;
}

.control button {
  background: #fff;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  text-align: center;
}

.control button:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #ccc;
}

.control button:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>
