<template>
  <div class="where-reservations-thanks">
    <div class="container">
      <a href="/users/home" class="where-reservations-thanks__close">
        <img :src="require('../../../../images/close.svg')" height="12px" />
      </a>
      <h2 class="where-reservations-thanks__title">
        Tudo certo! Sua reserva está confirmada
      </h2>
      <reservations-list :items="items" />
    </div>
    <div class="container mt-auto">
      <a class="btn btn-default" href="/users/home"> Finalizar </a>
    </div>
  </div>
</template>

<script>
import ReservationsList from "../../components/reservations-list.vue";

export default {
  components: { ReservationsList },
  props: ["currentUser", "reservations"],
  computed: {
    items() {
      let reservations = Object.assign(this.reservations);

      return reservations.map(reservation => {
        return {
          ...reservation,
          shouldDisplayDay: true,
          shouldDisplayNumber: true,
          hasCancelable: false,
        }
      });
    },
  },
};
</script>

<style lang="scss">
.where-reservations-thanks {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.where-reservations-thanks__close {
  width: 100%;
  text-align: right;
  display: inline-block;
  padding-top: 20px;
  padding-right: 10px;
}
.where-reservations-thanks__title {
  margin-top: 20px;
  text-align: left;
}
</style>
