import BaseService from "./BaseService";

class SpaceService extends BaseService {
  static async spaceById(id) {
    const axios = this.initialize();

    const request = await axios.get(`/api/spaces/${id}`);
    return request.data;
  }

  static async spaceByCodeAndFloorId(code, floorId) {
    const axios = this.initialize();

    const request = await axios.get(`/api/spaces/${code}/${floorId}`);
    return request.data;
  }

  static async reservationsByDate(code, date) {
    const axios = this.initialize();

    const request = await axios.get(`/api/spaces/${code}/reservations_by_date/${date}`);
    return request.data;
  }
}

export default SpaceService;
