<template>
  <b-modal id="modalTimeInput" hide-header no-close-on-esc no-close-on-backdrop @hide="showScrollPicker = false">
    <template #default v-if="showScrollPicker">
      <div class="where-timer-picker">
        <scroll-picker-group class="mt-4">
          <div class="d-flex justify-content-center">
            <div class="mr-3">
              <span class="title ml-5 mr-5">Duração</span>
              <scroll-picker :options="options" v-model="value"/>
            </div>
          </div>
        </scroll-picker-group>
      </div>
    </template>
    <template #modal-footer>
      <button type="button" class="btn btn-outlined" @click="cancel()">
        CANCELAR
      </button>
      <button type="button" class="btn btn-outlined btn-outlined-blue" @click="handleDuration()">
        OK
      </button>
    </template>
  </b-modal>
</template>

<script>
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker"

export default {
  name: "WhereDurationPicker",
  props: ["currentValue"],
  components: { ScrollPicker, ScrollPickerGroup },
  data() {
    return {
      showScrollPicker: false,
      value: 1,
      options: [
        {value: 1, name: "Dia todo"},
        {value: 15, name: "15 minutos"},
        {value: 30, name: "30 minutos"},
        {value: 60, name: "1 hora"},
      ]
    }
  },
  mounted() {
      this.$bvModal.show('modalTimeInput');
      this.value = this.$props.currentValue
      this.$nextTick = setTimeout(() => {
        this.showScrollPicker = true
      }, 5)
  },
  methods: {
    handleDuration() {
      this.$emit("changeDuration", this.value);
      this.$bvModal.hide('modalTimeInput');
    },
    cancel() {
      this.$emit("changeDuration", this.$props.currentValue);
      this.$bvModal.hide('modalTimeInput');
    },
  },
};
</script>

<style lang="scss">
.title {
  font-size: 12px!important;
}

.where-timer-picker {
  .vue-scroll-picker {
    height: 13em;


    .vue-scroll-picker-list {
      .vue-scroll-picker-list-rotator {
        font-weight: bold;
        font-size: 24px;
        color: #82818C;

        .vue-scroll-picker-item {
          height: 4rem;
          color: #82818C!important;

          &.-selected {
            color: #001B98!important;

            &::after {
              content: '';
              border-bottom: 3px solid #001B98;
              width: 100%;
              display: block;
            }
          }
        }
      }
    }

    .vue-scroll-picker-layer {
      .top {
        border-bottom: none;
        height: calc(30% - 1em);
      }
      .middle {
        top: calc(40% - 1em);
      }
      .bottom {
        border-top: none;
        height: calc(47% - 1em);
      }
    }
  }
}
</style>