var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qrcode-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"my-3"},[_c('a',{staticClass:"menu-icon",attrs:{"href":"/users/home"}},[_c('img',{staticClass:"mr-3",attrs:{"src":require('../../../../images/arrow_left.svg'),"height":"12px"}}),_vm._v("\n        Escaneie com QR-code\n      ")])]),_vm._v(" "),(_vm.loading || _vm.error)?_c('p',{staticClass:"where-qrcode-status"},[(_vm.loading)?[_vm._v("Carregando...")]:_vm._e(),(_vm.error)?[_vm._v("Não foi possível carregar uma câmera.")]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('canvas',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"canvas","hidden":""}})]),_vm._v(" "),_c('b-modal',{attrs:{"id":"reservation-status-to-early","aria-labelledby":"ModalLabel","aria-hidden":"true","hide-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('h2',[_c('strong',{attrs:{"id":"reservation-code"}},[_vm._v(_vm._s(_vm.space.code))]),_c('br'),_vm._v("\n        confirmação de reserva\n      ")]),_vm._v(" "),_c('p',{staticClass:"mt-3 mb-5"},[_vm._v("\n        Ainda é cedo, volte a partir de\n        "),_c('u',{attrs:{"id":"reservation-limit"}},[_vm._v(_vm._s(_vm.reservation.formated_checkable_at))]),_vm._v("\n        para fazer o check-in e confirmar sua reserva.\n      ")])]},proxy:true},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('button',{staticClass:"btn btn-outlined",attrs:{"type":"button"},on:{"click":function($event){return ok()}}},[_vm._v("\n        ENTENDI\n      ")])]}}])}),_vm._v(" "),_c('b-modal',{attrs:{"id":"reservation-status-checkin","aria-labelledby":"ModalLabel","aria-hidden":"true","hide-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('h2',[_c('strong',{attrs:{"id":"reservation-code"}},[_vm._v(_vm._s(_vm.space.code))]),_c('br'),_vm._v("\n        confirmação de check-in\n      ")]),_vm._v(" "),_c('p',{staticClass:"mt-3 mb-5"},[_vm._v("\n        Você tem uma reserva nesse espaço, deseja fazer o check-in?\n      ")])]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button',{attrs:{"type":"button"},on:{"click":function($event){return cancel()}}},[_vm._v("VOLTAR")]),_vm._v(" "),_c('button',{staticClass:"btn btn-outlined",attrs:{"type":"button"},on:{"click":function($event){return _vm.checkinOrCheckout()}}},[_vm._v("\n        FAZER CHECK-IN\n      ")])]}}])}),_vm._v(" "),_c('b-modal',{attrs:{"id":"reservation-status-checkout","aria-labelledby":"ModalLabel","aria-hidden":"true","hide-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('h2',[_c('strong',{attrs:{"id":"reservation-code"}},[_vm._v(_vm._s(_vm.space.code))]),_c('br'),_vm._v("\n        reserva já confirmada\n      ")]),_vm._v(" "),_c('p',{staticClass:"mt-3 mb-5"},[_vm._v("\n        Tudo certo, você já fez o check-in e sua reserva já está liberada!\n      ")])]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button',{attrs:{"type":"button"},on:{"click":function($event){return cancel()}}},[_vm._v("VOLTAR")]),_vm._v(" "),_c('button',{staticClass:"btn btn-outlined",attrs:{"type":"button"},on:{"click":function($event){return _vm.checkinOrCheckout()}}},[_vm._v("\n        FAZER CHECK-OUT\n      ")])]}}])}),_vm._v(" "),_c('b-modal',{attrs:{"id":"modal-error","aria-labelledby":"ModalLabel","aria-hidden":"true","hide-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('h2',[_c('strong',{attrs:{"id":"reservation-code"}},[_vm._v(_vm._s(_vm.space.code))]),_vm._v(" "),_c('br'),_vm._v("\n        Indisponível\n      ")]),_vm._v(" "),_c('p',{staticClass:"mt-3 mb-5"},[_vm._v("\n        "+_vm._s(_vm.reservationError)+"\n      ")])]},proxy:true},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('button',{staticClass:"btn btn-outlined",attrs:{"type":"button"},on:{"click":function($event){return ok()}}},[_vm._v("\n        FECHAR\n      ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }