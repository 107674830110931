/* eslint no-console: 0 */

import Vue from "vue";
import TurbolinksAdapter from "vue-turbolinks";
import UsersHome from "./screens/users/home";
import UsersMenu from "./screens/users/menu";
import UsersSearch from "./screens/users/search";
import UsersQrcode from "./screens/users/qrcode";
import UsersRecover from "./screens/users/recover";
import UsersHowItWorks from "./screens/users/howItWorks";
import UsersProfile from "./screens/users/profile";
import UsersForm from "./screens/users/form";
import ReservationsIndex from "./screens/reservations";
import ReservationsThanks from "./screens/reservations/thanks";
import AdminHome from "./screens/admin/home";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "floating-vue/dist/style.css";

Vue.use(BootstrapVue);
Vue.use(TurbolinksAdapter);

document.addEventListener("turbolinks:load", () => {
  const screens = [
    {
      element: "#users-home",
      component: UsersHome,
    },
    {
      element: "#users-menu",
      component: UsersMenu,
    },
    {
      element: "#users-search",
      component: UsersSearch,
    },
    {
      element: "#users-qrcode",
      component: UsersQrcode,
    },
    {
      element: "#users-recover",
      component: UsersRecover,
    },
    {
      element: "#users-howItWorks",
      component: UsersHowItWorks,
    },
    {
      element: "#users-profile",
      component: UsersProfile,
    },
    {
      element: "#users-form",
      component: UsersForm,
    },
    {
      element: "#reservations-index",
      component: ReservationsIndex,
    },
    {
      element: "#reservations-thanks",
      component: ReservationsThanks,
    },
    {
      element: "#admin-home",
      component: AdminHome,
    },
  ];

  screens.forEach((screen) => {
    const element = document.querySelector(screen.element);

    if (element != null) {
      const props = JSON.parse(element.getAttribute("data"));

      new Vue({
        el: screen.element,
        render: (h) => h(screen.component, { props }),
      });
    }
  });
});
