import BaseService from "./BaseService";

class SearchService extends BaseService {
  static async submit(term) {
    const axios = this.initialize();
    const request = await axios.get(`/api/resources/search`, {
      params: { term: term },
    });
    return request.data;
  }
  static async favorite(item) {
    const axios = this.initialize();
    const request = await axios.post(`/api/favorites`, {
      id: item.id,
      type: item.type,
    });
    return request.data;
  }
  static async unfavorite(item) {
    const axios = this.initialize();
    const request = await axios.delete(`/api/favorite`, {
      params: {
        id: item.id,
        type: item.type,
      },
    });
    return request.data;
  }
}

export default SearchService;
