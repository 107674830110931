<template>
  <div class="where-progress-bar">
    <div class="bar">
      <div class="blue" v-if="blue" :style="`min-width: ${width(blue)}%`">
        <template v-if="showInnerValues">{{ percentage(blue) }}% <span v-if="showCountValue && blue > 0">({{ blue }})</span></template>
      </div>
      <div
        class="yellow"
        v-if="yellow"
        :style="`min-width: ${total ? width(blue) + width(yellow) : yellow}%`"
      >
        <template v-if="showInnerValues">{{ percentage(yellow) }}%</template>
      </div>
      <div
        class="salmon"
        v-if="salmon"
        :style="`min-width: ${
          total ? width(blue) + width(yellow) + width(salmon) : salmon
        }%`"
      >
        <template v-if="showInnerValues">{{ percentage(salmon) }}%<span v-if="showCountValue">({{ salmon }})</span></template>
      </div>
    </div>
    <ul v-if="Object.keys(captions).length" class="caption">
      <li v-if="blue" class="caption-item">
        <span class="caption-circle blue"></span>
        <p>{{ blue }} {{ captions.blue }}</p>
      </li>
      <li v-if="yellow" class="caption-item">
        <span class="caption-circle yellow"></span>
        <p>{{ yellow }} {{ captions.yellow }}</p>
      </li>
      <li v-if="salmon" class="caption-item">
        <span class="caption-circle salmon"></span>
        <p>{{ salmon }} {{ captions.salmon }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    blue: {
      type: Number,
      default: 0,
    },
    yellow: {
      type: Number,
      default: 0,
    },
    salmon: {
      type: Number,
      default: 0,
    },
    captions: {
      type: Object,
      default: () => ({}),
    },
    showInnerValues: {
      type: Boolean,
      default: false,
    },
    showCountValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    width(value) {
      if (!this.total) return value;

      return (100 * value) / this.total;
    },
    percentage(value) {
      return Math.floor((value * 100) / this.total)
    }
  },
};
</script>

<style lang="scss" scoped>
.bar {
  display: flex;
  width: 100%;
  height: 16px;
  background: #ececec;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  > div {
    height: 16px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;
    font-size: 12px;
    color: #fff;
    padding-left: 6px;
    padding-right: 6px;

    &.blue {
      background: #001b98;
      z-index: 3;
    }

    &.yellow {
      background: #f1ecce;
      z-index: 2;
    }

    &.salmon {
      background: #e7baab;
      z-index: 1;
    }
  }
}

.caption {
  display: flex;
  column-gap: 16px;
  margin: 0;
  padding: 0;
  margin-top: 18px;
}

.caption-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  list-style-type: none;

  p {
    margin: 0 !important;
    color: #82818c;
    font-size: 12px;
    line-height: 1;
  }
}

.caption-circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50px;
  flex-shrink: 0;

  &.blue {
    background: #001b98;
  }

  &.yellow {
    background: #f1ecce;
  }

  &.salmon {
    background: #e7baab;
  }
}
</style>
