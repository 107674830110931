import BaseService from "./BaseService";

class ReservationService extends BaseService {
  static async slotStatus(params) {
    const axios = this.initialize();

    const request = await axios.get(`/api/spaces/status`, { params });
    return request.data;
  }

  static async getUser({ user }) {
    const axios = this.initialize();

    const request = await axios.get(`/api/users/${user}`);
    return request.data;
  }

  static async getGroups(params) {
    const axios = this.initialize();

    const request = await axios.get(`/api/spaces/groups`, { params });
    return request.data;
  }

  static create(params) {
    const axios = this.initialize();

    return axios.post(`/api/reservations`, params);
  }

  static getAvailabilityForMoreDays(payload) {
    const axios = this.initialize();

    return axios.post(`/api/reservations/availability_for_more_days`, payload);
  }

  static step1(payload) {
    const axios = this.initialize();

    return axios.post("/api/step1", payload);
  }

  static step2(payload) {
    const axios = this.initialize();

    return axios.post("/api/step2", payload);
  }

  static getFloorMap(payload) {
    const axios = this.initialize();

    return axios.post("/api/floor_map", payload);
  }

  static step3(payload) {
    const axios = this.initialize();

    return axios.post("/api/step3", payload);
  }
}

export default ReservationService;
