<template>
  <div id="reservation-index">
    <div class="d-flex flex-column align-items-center building">
      <span class="location-name" id="location-name">{{
        currentLocation.name
      }}</span>
      <span id="floor-name">{{ currentFloor.name }}</span>
      <span class="buildings-floors-data__progress">
        <span class="progress" id="progress-bar"></span>
      </span>
      <span id="floor-available">
        <span id="floor-available-count">{{ showAvailability }}</span>
      </span>
    </div>

    <div class="controls">
      <a
        href="javascript:;"
        @click="handlePrev"
        v-if="previousLocation"
        class="control-prev"
        id="control-prev"
      >
        <img
          :src="require('../../../../images/button-prev.svg')"
          height="60px"
        />
      </a>
      <a
        href="javascript:;"
        v-if="nextLocation"
        @click="handleNext"
        class="control-next"
        id="control-next"
      >
        <img
          :src="require('../../../../images/button-next.svg')"
          height="60px"
        />
      </a>
    </div>
    <ul id="floor-list">
      <li
        v-for="(floor, index) in currentLocation.floors"
        :class="floor.class"
        :key="index"
        :id="`floor-${index}`"
        :style="{ 'z-index': 999 - index }"
        @click="selectFloor(index, $event)"
      >
        <span style="color: transparent">{{ floor.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import ReservationService from "../../services/ReservationService";
import { format } from "date-fns";

export default {
  data() {
    return {
      locations: [],
      locationId: null,
      date: "",
      currentLocation: { name: "", floors: [{ name: "" }] },
      currentFloor: { name: "", availability: 0, availability_percentage: 0 },
      progress: {},
    };
  },
  async mounted() {
    this.progress = $("#progress-bar");
    await this.step1Data();
  },
  methods: {
    handlePrev() {
      if (this.previousLocation) {
        this.locationId = this.previousLocation.id;
        this.step1Data({ ...this.params });
      }
    },
    handleNext() {
      if (this.nextLocation) {
        this.locationId = this.nextLocation.id;
        this.step1Data({ ...this.params });
      }
    },
    async step1Data(payload = {}) {
      try {
        const { data } = await ReservationService.step1(payload);

        this.date = data.date;
        this.locations = data.locations;
        this.locationId = data.locationId;

        let list = document.getElementById("floor-list");
        list.childNodes.forEach((floor) => {
          floor.classList.value = "";
        });

        this.currentLocation = this.locations.filter(
          (item) => item.id === this.locationId
        )[0];
        this.currentLocation.floors.filter((floor, index) => {
          floor.class = index === 0 ? "asd floor-top" : "zxc";
        });

        this.$emit("setLocation", this.currentLocation);

        this.currentFloor = this.currentLocation.floors[0];
        this.setTop(list.childNodes[0]);
      } catch (error) {
        console.log(error);
      }
    },
    selectFloor(index, $event) {
      let act = $event.target.classList.value.split(" ").includes("floor-top");

      if (act) {
        this.showFloor($event.target, index);
      } else {
        this.hideFloors($event.target, index);
        this.setTop($event.target, index);
      }
    },
    showFloor(current, index) {
      const previousIndex = index - 1;
      this.currentFloor = this.currentLocation.floors[0];

      if (previousIndex < 0) return;

      const previousFloor = document.getElementById(`floor-${previousIndex}`);
      this.setTop(previousFloor);

      current.classList.remove("floor-top");
      this.currentFloor = this.currentLocation.floors[previousIndex];
    },
    hideFloors(current, index) {
      let idx = index;
      current.parentElement.childNodes[0].classList.remove("floor-top");
      this.currentFloor = this.currentLocation.floors[0];

      if (index <= 0) return;

      this.currentFloor = this.currentLocation.floors[idx];
      idx--;

      for (; idx >= 0; idx--) {
        const node = current.parentElement.childNodes[idx];
        if (node) node.classList.add("floor-exit");
      }
    },
    setTop(element) {
      this.progress.css(
        "width",
        this.currentFloor.availability_percentage + "%"
      );

      element.classList.remove("floor-exit");
      element.classList.add("floor-top");
    },
  },
  computed: {
    params() {
      return {
        locationId: this.locationId,
        date: format(this.$parent.currentDate, "yyyy-MM-dd"),
      };
    },
    locationIds() {
      return this.locations.map((item) => item.id);
    },
    previousLocation() {
      const index = this.locationIds.findIndex(
        (item) => item === this.locationId
      );
      const newIndex = index - 1;
      const target = this.locations[newIndex];

      return target;
    },
    nextLocation() {
      const index = this.locationIds.findIndex(
        (item) => item === this.locationId
      );
      const newIndex = index + 1;
      const target = this.locations[newIndex];

      return target;
    },
    showAvailability() {
      let count = this.currentFloor.availability;

      if (count === 0) {
        return "Nenhum lugar disponível";
      } else if (count === 1) {
        return "1 lugar disponível";
      } else {
        return `${count} lugares disponíveis`;
      }
    },
  },
  watch: {
    "$parent.currentDate"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.step1Data({ ...this.params });
      }
    },
    currentFloor(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("setFloor", this.currentFloor);
        this.progress.css(
          "width",
          this.currentFloor.availability_percentage + "%"
        );
      }
    },
  },
};
</script>

<style lang="scss">
#reservation-index {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 3em;
  overflow: overlay;

  @media (min-width: 768px) {
    justify-content: center;
  }
}
</style>
